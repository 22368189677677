import {useLocation} from "react-router-dom";
import {setGuestStore} from "../util/guestStorage";

const useIsGuest = () => {
    const location = useLocation();
    const isGuest = location.pathname.includes("/guest");
    setGuestStore(isGuest);
    return { isGuest, isHost: !isGuest };
};
export default useIsGuest;
