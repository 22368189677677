import {RouterProvider} from "react-router-dom";
import router from "./router";
import React from "react";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {RecoilRoot} from "recoil";

const queryClient = new QueryClient()
const App = () => {
    return (
        <RecoilRoot>
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={router}/>
            </QueryClientProvider>
        </RecoilRoot>
    )
}

export default App;