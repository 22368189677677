import {useDroppable} from "@dnd-kit/core";
import Typography from "@mui/material/Typography";

export default function DroppableGap(
    {
        children,
        bgcolor,
        identifier,
        content,
        onClick,
        ...otherProps
    }
){
    const {isOver, setNodeRef} = useDroppable({
        id: identifier
    });

    return (
        <Typography
            component="span"
            onClick={onClick}
            ref={setNodeRef}
            sx={{
                width: content ? undefined : 80,
                height: content ? undefined : 26,
                display: 'inline-block',
                backgroundColor: isOver ? '#DCF8C7' : '#EAEAEAFF',
                borderRadius: 1,
                paddingX: content ? 1 : undefined,
                fontWeight: 'bold',
                color: content ? undefined : 'transparent',
                cursor: content ? 'pointer' : 'default',
                fontSize: 15,
        }}
            {...otherProps}
        >
            {content ?? '_'}
        </Typography>
    );
}