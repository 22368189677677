import {useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {useEffect, useState} from "react";
import gameApi from "../api/gameApi";
import Navbar from "../components/navbar/Navbar";
import {Stack} from "@mui/material";
import HintItems from "../components/HintItems";
import useIsGuest from "../hooks/useIsGuest";
import Chatbar from "../components/Chatbar";
import ProblemModal from "../components/ProblemModal";
import {isGuestFromStore} from "../util/guestStorage";
import {useTranslation} from "react-i18next";
import InstructionsModal from "../components/InstructionsModal";
import Game2 from "../game/Game2";
import JoinNewMemberModal from "../components/JoinNewMemberModal";
import PuzzleWaitingStartModal from "../components/puzzle-waiting-start/PuzzleWaitingStartModal";
import {useRoomContext} from "../context/RoomContextProvider";
import {usePuzzleContext} from "../context/PuzzleContextProvider";
import PuzzleWaitingAnswersModal from "../components/puzzle-waiting-answers/PuzzleWaitingAnswersModal";
import AreYouStillThereModal from "../components/are-you-still-there/AreYouStillThereModal";
import gameApiV2 from "../api/gameApiV2";

export default function PuzzlePage() {
    const {roomId, myMemberId} = useRoomContext();
    const {puzzleId, gameData, isPuzzleStarted, setGameData} = usePuzzleContext();

    const {gameId, groupId} = useParams();
    const navigate = useNavigate();
    const [problemOpen, setProblemOpen] = useState(false);
    const [instructionsOpen, setInstructionsOpen] = useState(false);
    const {isGuest} = useIsGuest();
    const {i18n} = useTranslation();
    const {t: tPuzzle} = useTranslation('puzzle');
    const [hostName, setHostName] = useState('');

    const toggleProblemModal = () => {
        setProblemOpen(prevOpen => !prevOpen)
    }
    const toggleInstructionModal = () => {
        setInstructionsOpen(prevOpen => !prevOpen)
    }

    useEffect(() => {
        window.sessionStorage.setItem("roomId", roomId);
        const isGuestFromSession = isGuestFromStore() ?? isGuest;

        /**
         * Validates game (if puzzle is purchased && if puzzle is completed)
         * Also returns saved language (??) TODO: why language here? otherwise could be simply 'validate puzzle'
         */
        gameApi.getGame(roomId, puzzleId, isGuestFromSession)
            .then((data) => {
                data?.lang && i18n.changeLanguage(data?.lang);
                setHostName(data?.hostName)
            })
            .catch(({response}) => navigate('/error', {state: {title: response?.data?.message}}))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameId, groupId, isGuest, navigate, i18n]);

    useEffect(() => {
        const handlePopstate = (event) => {
            if (event && event.state) {
                // Reload the page
                window.location.reload();
            }
        };

        // Add the popstate event listener
        window.addEventListener('popstate', handlePopstate);

        return () => {
            // Remove the event listener when the component unmounts
            window.removeEventListener('popstate', handlePopstate);
        };
    }, []);

    window.addEventListener('popstate', function (event) {
        if (event.state && event.state.reload === true) {
            // Reload the page
            window.location.reload();
        }
    });

    // To force a page reload when the user navigates back
    window.history.pushState({reload: true}, '');

    const getMyGameplay = () => {
        return myMemberId
            ? gameData?.puzzle.gameplayByMemberId[myMemberId]
            : null;
    }


    const getName = (data) => {
        if (data?.gameName !== undefined && data?.puzzle?.name !== undefined) {
            return tPuzzle(data?.gameName) + ': ' + tPuzzle(data?.puzzle?.name);
        }
        return '';
    }

    const onSendTimerMessage = (message) => {
        const req = {message: message};
        console.log("sending message: ", message)

        gameApiV2.sendTimerMessage(roomId, puzzleId, req, myMemberId)
            .then(response => {
                setGameData(response?.data);
            })
    }

    return (
        <Stack sx={{height: '100%'}}>
            <Navbar
                name={getName(gameData)}
                timerMessages={getMyGameplay()?.timerMessages}
                hintItems={<HintItems hints={getMyGameplay()?.hint}/>}
                onResetPuzzle={toggleProblemModal}
                toggleInstructionModal={toggleInstructionModal}
                onSendTimerMessage={onSendTimerMessage}
            />
            <Chatbar data={gameData} />
            <ProblemModal open={problemOpen} toggle={toggleProblemModal}/>
            <InstructionsModal open={instructionsOpen} toggle={toggleInstructionModal}/>
            <JoinNewMemberModal hostName={hostName}/>
            <PuzzleWaitingStartModal/>
            <PuzzleWaitingAnswersModal/>
            <AreYouStillThereModal/>
            <Game2 matchReady={!!isPuzzleStarted}/>
        </Stack>
    );
}
