import * as React from 'react';
import {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Box from "@mui/material/Box";
import {Stack, styled} from "@mui/material";
import TaggerCarousel from "../TagOptionsCarousel";
import PaperSegment from "../../PaperSegment";
import AnswerSubmitButton from "../AnswerSubmitButton";
import AnswerDialogHeader from "../AnswerDialogHeader";
import AnswerDialogPaper from "../AnswerDialogPaper";
import {useTranslation} from "react-i18next";
import {DndContext} from "@dnd-kit/core";
import Draggable from "./Draggable";
import DroppableGap from "./DroppableGap";
import dragDropIcon from '../../../icons/IconDragDrop.png';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogSx = styled(Dialog)`
  .MuiDialog-container .MuiPaper-root {
    border-radius: 0;
    background-color: #e9e4de !important;
  }
`

const OptionListContainerSx = styled(Stack)`
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 10px;
  flex-wrap: wrap;
  gap: 4px;
  row-gap: 10px;
`

export default function DragOptionsDialog(
    {
        message,
        open,
        availableOptions,
        onClose,
        onSubmit,
        unique,
    }
) {
    const { t } = useTranslation("dragOptionsDialog");
    const { t : transPuzzle } = useTranslation("puzzle");

    const handleClose = () => {
        onClose();
    };

    const onSubmitClick = () => {
        const sortedGapFills = Object.entries(filledGaps).sort((a, b) => {
            const keyA = a[0];
            const keyB = b[0];

            const numA = parseInt(keyA.split('-')[1], 10);
            const numB = parseInt(keyB.split('-')[1], 10);

            return numA - numB;
        });

        const translatedAnswers = sortedGapFills
            .map(([, selectedOptionTranslationKey]) => selectedOptionTranslationKey)
            .map(transPuzzle);

        onClose();
        onSubmit(translatedAnswers)
    }

    const renderGallery = () => {
        if (message?.action?.media?.length > 0) {
            return <TaggerCarousel images={message?.action?.media} />
        }
    }

    const renderDraggableOption = (option) => {
        const translated = transPuzzle(option);
        return (
            <Draggable key={option} id={option}>
                {translated}
            </Draggable>
        );
    }

    const text = transPuzzle(message?.action?.text);
    const textParts = text.split('<gap />');
    const [filledGaps, setFilledGaps] = useState({});

    function handleDragEnd(event) {
        if (event.over && event.over.id.includes('gap')) {
            setFilledGaps(prev => ({ ...prev, [event.over.id]: event.active.id}))
        }
    }

    const onRemoveGapFill = (gapId) => {
        setFilledGaps(prev => ({ ...prev, [gapId]: undefined }))
    }

    const renderGap = (index) => {
        if (index >= textParts.length - 1) {
            return null;
        }
        const gapId = `gap-${index + 1}`;
        return (
            <DroppableGap
                identifier={gapId}
                onClick={() => onRemoveGapFill(gapId)}
                content={filledGaps[gapId]
                    ? transPuzzle(filledGaps[gapId])
                : null}
            />
        )
    }

    const onlyNotUsed = (option) => {
        if (unique) {
            return !Object.values(filledGaps).includes(option);
        }
        return true;
    }

    const gapCount = message?.action?.answer[0]?.split('-')?.length;

    return (
        <DialogSx fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AnswerDialogHeader onBack={handleClose} />
            <AnswerDialogPaper>
                <Stack mb={5} mx={2}>
                    {renderGallery()}
                </Stack>

                <Stack px={2}>
                    <DndContext onDragEnd={handleDragEnd}>
                        <PaperSegment>
                            <Typography variant="h5" component="div">
                                {t('availableOptions', { count: availableOptions?.length })}
                            </Typography>
                            <OptionListContainerSx>
                                {availableOptions
                                    ?.filter(onlyNotUsed)
                                    .sort()
                                    .map(renderDraggableOption)
                                }
                            </OptionListContainerSx>
                        </PaperSegment>

                        <PaperSegment mt={1}>
                            <Typography variant="h5" component="div" fontWeight="bold">
                                {t('textChallengeTitle')}
                            </Typography>
                            <Stack
                                direction="row"
                                gap={2}
                                alignItems="center"
                                my={1}
                                px={2}
                                py={1}
                                bgcolor={"#dae2f3"}
                                borderRadius={1}
                            >
                                <img src={dragDropIcon} width={24} style={{ objectFit: 'contain' }} alt={"drag'n'drop"}/>
                                <Typography component="div" fontSize={14} sx={{ opacity: 0.7 }}>
                                    {t('textChallengeDescription')}
                                </Typography>
                            </Stack>
                            <Typography lineHeight={2} sx={{ marginTop: 2 }}>
                                {textParts.map((part, index) => [
                                    <React.Fragment key={index}>
                                        <Typography component="span">
                                            {part}
                                        </Typography>
                                        {renderGap(index)}
                                    </React.Fragment>
                                ])}
                            </Typography>
                            <Box mt={2}>
                                <AnswerSubmitButton
                                    onClick={onSubmitClick}
                                    disabled={gapCount > Object.values(filledGaps).length}
                                >
                                    {t('submitButton')}
                                </AnswerSubmitButton>
                            </Box>
                        </PaperSegment>
                    </DndContext>
                </Stack>
            </AnswerDialogPaper>
        </DialogSx>
    );
}
