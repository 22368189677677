import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import PersonIcon from '@mui/icons-material/Person';
import {useTranslation} from "react-i18next";

const GameSeat = ({player, myMemberId, renderEndComponent}) => {
    const { t } = useTranslation('gameSeat');
    const isMe = Number(myMemberId) === Number(player?.memberId);

    const renderYouTag = () => {
        return (
            <Stack
                py={0.25}
                px={1}
                sx={{
                    backgroundColor: '#fff213',
                    left: -20,
                    top: -8.
                }}
                ml={0.5}
                borderRadius={2}
                position="absolute"
            >
                <Typography fontSize={11} color="black" fontWeight="bold">
                    {t('youTag')}
                </Typography>
            </Stack>
        )
    }
    const renderIndicator = (isEmpty) => {
        return (
            <Stack
                sx={{backgroundColor: isEmpty ? '#313b50' : "#398896"}}
                borderRadius="50%"
                minHeight={46}
                minWidth={46}
                maxHeight={46}
                maxWidth={46}
                alignItems="center"
                justifyContent="center"
                position="relative"
            >
                {!isEmpty && <PersonIcon/>}
                {isMe && renderYouTag()}
            </Stack>
        )
    }

    const renderPlayerDetails = (player) => {
        if (!player) {
            return (
            <Stack sx={{ opacity: 0.8 }}>
                <Typography color="#454f65" fontWeight="normal">
                    {t('emptyTitle')}
                </Typography>
                <Typography color="#454f65" fontSize={12} sx={{opacity: 0.6}}>
                    {t('emptySubtitle')}
                </Typography>
            </Stack>
            )
        }

        return (
            <Stack
                direction="row"
                gap={1}
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                position="relative"
            >
                <Stack direction="row" gap={1} alignItems="flex-start">
                    <Stack>
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Typography fontWeight="normal">
                                {player.name}
                            </Typography>
                        </Stack>
                        <Typography fontSize={12} sx={{opacity: 0.6}}>
                            {player.isGuest ? t('guestSubtitle') : t('hostSubtitle')}
                        </Typography>
                    </Stack>
                </Stack>
                {renderEndComponent && renderEndComponent(player)}
            </Stack>
        )
    }

    return (
        <Stack direction="row" gap={1} alignItems="center">
            {renderIndicator(!player)}
            {renderPlayerDetails(player)}
        </Stack>
    )
}

export default GameSeat;