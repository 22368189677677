import * as React from 'react';
import {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import TimerSharpIcon from '@mui/icons-material/TimerSharp';
import {alpha, Drawer, List, ListItem, ListItemButton, ListItemText, Stack} from "@mui/material";
import Divider from "@mui/material/Divider";
import {useNavigate} from "react-router-dom";
import useIsGuest from "../../hooks/useIsGuest";
import DifficultyDropdown from "./DifficultyDropdown";
import {useStopwatch} from "react-timer-hook";
import {setGuestStore} from "../../util/guestStorage";
import {useTranslation} from "react-i18next";
import {useRecoilState} from "recoil";
import {timerFinishedAtState, timerStartedAtState} from "../../recoil_state";
import {useRoomContext} from "../../context/RoomContextProvider";
import LangInput from "./LangInput";
import useSaveLanguageChange from "../../hooks/useSaveLanguageChange";
import TimerMessagesToggle from "./TimerMessagesToggle";

export default function Navbar(
    {
        name = "",
        hintItems,
        onResetPuzzle,
        toggleInstructionModal,
        timerMessages,
        onSendTimerMessage
    }
) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [finishTime, setFinishTime] = useState(null);
    const {isGuest} = useIsGuest();
    const {t, i18n} = useTranslation('navbar');
    const {roomId} = useRoomContext();
    const {
        totalSeconds,
        seconds,
        minutes,
        hours,
        reset,
        pause,
    } = useStopwatch({autoStart: false});

    const [timerStartedAt] = useRecoilState(timerStartedAtState);
    const [timerFinishedAt] = useRecoilState(timerFinishedAtState);

    /**
     * This use effect is responsible to make timer in sync.
     * 1. If timer is already running and we got the start time from the API, set the timer to that date
     * 2. If timer is not started, wait till the start time (in case it is delayed) and then start ticking
     * 3. Once timer is running, we hide overlay. if we are waiting, we show countdown.
     * 3.1. Countdown is managed in another component but using the same timer state.
     */
    useEffect(() => {
        if (timerStartedAt && timerFinishedAt === null) {
            const futureStart = new Date(timerStartedAt);
            const now = new Date();

            if (futureStart > now) {
                // Calculate the time remaining until the timer should start
                const timeout = futureStart.getTime() - now.getTime();

                // Using setTimeout to delay the reset of the stopwatch
                const timer = setTimeout(() => {
                    reset(futureStart);
                }, timeout);

                return () => clearTimeout(timer);
            } else {
                // timer was already started, start the timer immediately and in sync
                const stopwatchOffset = new Date();
                const playerStart = new Date(timerStartedAt);
                let secondsDiff = (stopwatchOffset.getTime() - playerStart.getTime()) / 1000;
                stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + secondsDiff);
                reset(stopwatchOffset);
            }
        }
        if (timerFinishedAt !== null) {
            pause();
            /**
             * Unnable to set timer to finishedAt date. Therefore I calculate the difference and
             * just format minutes and seconds manually
             */
            const finishTime = timerFinishedAt instanceof Date ? timerFinishedAt : new Date(timerFinishedAt);
            const startTime = new Date(timerStartedAt);
            const differenceInSecondsFloat = (finishTime - startTime) / 1000;
            const differenceInSeconds = parseInt(differenceInSecondsFloat, 10);
            const minutes = Math.floor(differenceInSeconds / 60);
            const seconds = differenceInSeconds % 60;
            const finalTime = {minutes, seconds};
            setFinishTime(finalTime);
        }

    }, [timerStartedAt, reset, timerFinishedAt, pause])

    const findMessageBySecond = (sec) => {
        const result = timerMessages?.find(item => item.second === sec);
        return result ? result?.message : null;
    }

    useEffect(() => {
        if (timerMessages !== null) {
            const message = findMessageBySecond(totalSeconds);
            if (message !== null && message !== undefined) {
                onSendTimerMessage(message);
            }
        }
    }, [totalSeconds]) // eslint-disable-line

    const getFormattedTimeDisplay = (_hour, _minutes, _seconds) => {
        if (_hour >= 1) {
            return '1h+';
        }
        // console.log("total seconds: ", totalSeconds)
        let m = _minutes < 10 ? '0' + _minutes : _minutes;
        let s = _seconds < 10 ? '0' + _seconds : _seconds;
        return m + ":" + s;
    }

    const formatTime = () => {
        if (finishTime) {
            if (finishTime.minutes >= 60) {
                return getFormattedTimeDisplay(1, 0, 0); //1h+
            }
            return getFormattedTimeDisplay(0, finishTime.minutes, finishTime.seconds); //1h+
        }
        return getFormattedTimeDisplay(hours, minutes, seconds);
    }

    const onQuitClick = () => {
        setGuestStore(isGuest);
        navigate(`/${roomId}`)
    }

    const saveLanguage = useSaveLanguageChange({ roomId })

    const handleLanguageChange = (event) => {
        i18n.changeLanguage(event.target.value).then(saveLanguage);
    };

    return (
        <Box>
            <AppBar position="static" sx={{backgroundColor: '#111827', paddingY: '8px'}}>
                <Toolbar sx={{paddingX: '8px'}}>
                    <Stack gap={2} direction="row" alignItems="center" flexGrow={1}>
                        <Stack
                            sx={{backgroundColor: alpha("#fff", 0.13), padding: '6px 0'}}
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            justifyContent="center"
                            width="100px"
                            borderRadius="5px"
                        >
                            <TimerSharpIcon sx={{fontSize: 18}}/>
                            <Typography sx={{fontSize: 14}} component="div">{formatTime()}</Typography>
                        </Stack>
                        <Typography component="div" sx={{fontSize: 16, fontWeight: 500}}>
                            {name}
                        </Typography>
                    </Stack>
                    <Stack direction="row" minWidth="90px" justifyContent="flex-end">
                        <IconButton
                            size="large"
                            // edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={() => setOpen(true)}
                            sx={{
                                background: 'rgba(25, 34, 53, 1)',
                                borderColor: 'rgba(255, 255, 255, 0.06)',
                                width: '42px',
                                height: '42px'
                            }}

                        >
                            <MenuIcon/>
                        </IconButton>
                    </Stack>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor='right'
                open={open}
                onClose={() => setOpen(prevOpen => !prevOpen)}
                PaperProps={{sx: {width: "40%", minWidth: '300px'}}}
            >
                <Stack px="14px">
                    <List>
                        <ListItem sx={{paddingX: 0}}>
                            <DifficultyDropdown />
                        </ListItem>
                        {!isGuest && (
                            <ListItem sx={{paddingX: 0}}>
                                <LangInput onChange={handleLanguageChange}/>
                            </ListItem>
                        )}
                        <ListItem sx={{paddingX: 0}}>
                            <TimerMessagesToggle onChange={handleLanguageChange}/>
                        </ListItem>
                        <Divider sx={{borderColor: '#28354F', marginTop: 2}}/>
                        <ListItem sx={{paddingX: 0}}>
                            <ListItemButton onClick={toggleInstructionModal}><ListItemText primary={t('instructions')}/></ListItemButton>
                        </ListItem>
                        {!isGuest &&
                            <ListItem sx={{paddingX: 0}}>
                                <ListItemButton onClick={onResetPuzzle}><ListItemText
                                    primary={t('problem')}/></ListItemButton>
                            </ListItem>
                        }
                        <Divider sx={{borderColor: '#28354F'}}/>
                        {hintItems}
                        <Divider sx={{borderColor: '#28354F'}}/>
                        <ListItem sx={{paddingX: 0}}>
                            <ListItemButton onClick={onQuitClick}>
                                <ListItemText sx={{color: '#FF5757'}} primary={t('quit')}/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Stack>
            </Drawer>
        </Box>
    );
}
