export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    boxShadow: 24,
    p: 4,
    backgroundColor: "#1B2436",
    color: 'white',
    borderRadius: '20px',
}

export const getModalStyle = (props) => {
    return (
        {
            ...modalStyle,
            ...props,
        }
    )
}