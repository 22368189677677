const nlGeneral = ({
    tagOptionsDialog: {
        availableOptions: "Beschikbare opties ({{count}})",
        submitButton: "Verzenden",
        clearBtn: "Leeg",
        selected: "Geselecteerd",
        inOrder: "op volgorde",
    },
    dragOptionsDialog: {
        availableOptions: "Beschikbare opties ({{count}})",
        textChallengeTitle: "Vul de open plekken in",
        textChallengeDescription: "Sleep opties om de ontbrekende delen in te vullen",
        submitButton: "Verzenden",
    },
    taggerCarousel: {
        altText: "afbeelding taggen",
        confirmButton: "Bevestigen",
        submitButtonText: "Verzenden",
        tagAllImages: "Tag alle afbeeldingen"
    },
    resultsDialog: {
        resultsTitle: "Resultaten",
        resultsScoreDifficultyCap: "Jullie zijn te slim voor dit niveau! We hebben de moeilijkheidsgraad verhoogd naar uitdagend. Jullie krijgen andere informatie, meer punten en geen hulp meer van Steve... Wil je weer terug naar Standaard? Dan kan speler 1 tijdens de opdracht de moeilijkheid aanpassen in het menu.",
        hintsUsed: "Totaal gebruikte hints",
        incorrectScore: "Totaal incorrecte score",
        solutionsTried: "Totaal geprobeerde oplossingen",
        timeSpent: "Besteedde tijd",
        totalPlayerHintsUsed: "Totaal door speler gebruikte hints",
        totalGuestHintsUsed: "Totaal door gast gebruikte hints",
        totalPlayerSolutionsTried: "Totaal aantal onjuiste pogingen",
        grandTotalResults: "Resultaat alle puzzels",
        totalScore: "Score",
        congratulationsText: "Gefeliciteerd! Jullie hebben de zaak opgelost!",
        waitMessage: "Wacht tot de andere speler op resultaten drukt en probeer opnieuw!",
        goToMenu: "Ga naar Menu",
        continueButton: "Doorgaan",
        curiousFriends: "Wil je de score van jullie vrienden weten?",
        shareWithFriends: "Nodig ze uit en vergelijk jullie scores!",
        shareMessage: "{{playerName}} en {{partnerName}} dagen jullie uit voor een TV escaperoom! Ze hebben een score van {{score}} behaald voor {{puzzleName}} in {{gameName}}. Accepteer je deze uitdaging? Ga naar {{link}}",
        shareGrandTotalMessage: "{{playerName}} en {{partnerName}} dagen jullie uit voor een TV escaperoom! Ze hebben een score van {{score}} behaald in {{gameName}}. Accepteer je deze uitdaging? Ga naar {{link}}"
    },
    continuePuzzleVideo: {
        // the lab
        'Intro 1': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - Intro 2 - en druk tegelijk op Doorgaan.',
        'Intro 2': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 1. Open the door - en druk tegelijk op Doorgaan.',
        'Deceased persons': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 3. Password - en druk tegelijk op Doorgaan.',
        'Differences': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 1. Open the door - en druk tegelijk op Doorgaan.',
        'Drone': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 5. Poison - en druk tegelijk op Doorgaan.',
        'Enclose': 'Gefeliciteerd! Jullie hebben het samen opgelost. Klik op Doorgaan om je totaalscore te bekijken en jullie vrienden uit te dagen!',
        'Escape': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 1. Open the door - en druk tegelijk op Doorgaan.',
        'Open the door': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 2. Deceased persons - en druk tegelijk op Doorgaan.',
        'Password': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 4. Drone - en druk tegelijk op Doorgaan.',
        'Poison': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 6. Who did it? - en druk tegelijk op Doorgaan.',
        'Tools and wires': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 1. Open the door - en druk tegelijk op Doorgaan.',
        'Who did it': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 7. Enclose - en druk tegelijk op Doorgaan.',

        // paradise
        'The Blackout': 'Are you ready for the next challenge? Then start the next video  - 3. The Witnesses - and press Continue!',
        'The Traces': 'Are you ready for the next challenge? Then start the next video - 9. The Security Tapes - and press Continue!',
        'The Key': 'Are you ready for the next challenge? Then start the next video -  5. The Interrogation - and press Continue!',
        'Whodunnit': 'Well done, you solved it together! Press Continue to watch your total score and challenge your friends!',
        'The Missing Items': 'Are you ready for the next challenge? Then start the next video -  8. The Traces - and press Continue!',
        'The Knight': 'Are you ready for the next challenge? Then start the next video  - 2. The Blackout - and press Continue. ',
        'The Witnesses': 'Are you ready for the next challenge? Then start the next video  - 4. The Key - and press Continue!',
        'The Interrogation': 'Are you both ready for the next challenge? Then start the next video  - 6. The Card Game - and press Continue!',
        'The Security Tapes': 'Are you ready for the next challenge? Then start the next video - 10. Whodunnit - and press Continue!',
        'The Card Game': 'Are you ready for the next challenge? Then start the next video  - 7. The Missing Items - and press Continue!',
    },
    puzzleCard: {
        difficultyPlayed: "Gespeelde moeilijkheidsgraad:",
        hintsUsed: "Aantal gebruikte hints:",
        solutionsTried: "Aantal oplossingen geprobeerd:",
        expires: "Verloopt",
        seeStats: "Bekijk statistieken"
    },
    problemModal: {
        sorry: "Het spijt ons van de bug! Maar geen zorgen, we zijn hier om het weer op het juiste spoor te brengen!",
        clickContinue: "Door op doorgaan te klikken, start je de puzzel opnieuw op.",
        areYouSure: "Weet je het zeker?",
        reset: "Reset",
        guestNeedRejoin: "De gast moet opnieuw worden uitgenodigd. Deel de link met de gastspeler.",
        inviteContinue: "Uitgenodigd, ga door!",
        doneOrSupport: "Dat is het! Refresh deze pagina en het spel gaat door. Neem contact op met de klantenservice als dit probleem aanhoudt.",
        reloadPage: "Refresh pagina",
        problem: "Probleem?",
    },
    errorModal: {
        difficultyChanged: {
            title: "Moeilijkheidsgraad gewijzigd",
            description: "De host heeft de moeilijkheidsgraad van het spel gewijzigd. Je moet opnieuw deelnemen aan deze puzzel om verder te gaan.",
            button: "Opnieuw deelnemen"
        },
        unknown: {
            title: "Fout",
        }
    },
    instructionsModal: {
        // TODO: translations will depend by puzzle?
        instruction1: "Hoe werkt het spel?<br>" +
            "<br>1. A Toxic Case is een spel voor 2 spelers en gaat over een vermissingszaak. Om het spel te spelen heeft iedere speler zijn eigen smartphone nodig." +
            "<br>2. Beide spelers krijgen steeds dezelfde opdracht, maar ontvangen verschillende informatie.  " +
            "<br>3. Om de opdracht uit te voeren, kijken jullie samen naar dezelfde video. Jullie zullen de informatie die je hebt ontvangen moeten bespreken en samen moeten werken." +
            "<br>4. De videos kun je bekijken via Vimeo of het CouchClues Youtube kanaal. We raden aan om het op je TV te bekijken of te streamen, maar je kan elk scherm gebruiken wat je wil." +
            "<br>5. Iedere video start met een deel van het verhaal en daarna beelden die betrekking hebben op je opdracht. De beelden die je nodig hebt zullen steeds worden herhaald, zodat je een nieuwe kans hebt de zaak op te lossen.",
        instruction2: "Hints en scores<br>" +
            "<br>1. Zodra je een opdracht hebt gekregen start er een timer. Je kunt proberen de opdrachten zo snel mogelijk uit te voeren om een hoge score te halen, maar je kunt dit ook negeren en het rustig op je eigen tijd doen." +
            "<br>2. Als het niet lukt om de opdracht uit te voeren, kun je in het menu rechtsboven een hint gebruiken. De uitleg helpt als je geen idee hebt hoe je moet beginnen en de andere hints zijn er voor als je niet verder komt." +
            "<br>3. Beide spelers kunnen dezelfde hint bekijken zonder dat dit extra punten kost." +
            "<br>4. Kom je er met de hints niet uit, dan kun je op Oplossing klikken om de oplossing en de uitleg te zien.",
        instruction3: "Hoe start je het spel?<br>" +
            "<br>1. De speler die het spel heeft gekocht ontvangt een link. Met de link kan Speler 1 het spel opstarten." +
            "<br>2. Als speler 1 het spel opstart krijgt deze een nieuwe link, waarmee een gast speler kan worden uitgenodigd." +
            "<br>3. Het spel start met twee intro opdrachten om het spel te leren kennen." +
            "<br>3. Als je een bug of een ander probleem ervaart, kan Speler 1 op de knop Problemen klikken om de huidige opdracht opnieuw op te starten.",
        btnText: 'Begrepen!'
    },
    navbar: {
        inviteFriend: "Nodig een vriend uit",
        instructions: "Instructies",
        instructionsUrl: "https://couchclues.com/instructions/",
        problem: "Problemen?",
        quit: "Stoppen",
    },
    difficultyModal: {
        areYouSure: "Weet je zeker dat je de moeilijkheidsgraad wilt veranderen naar",
        dialogContent: "Door de moeilijkheidsgraad te veranderen, start je deze puzzel opnieuw. \n Je gastspeler moet de pagina refreshen om verder te gaan. Weet je zeker dat je wilt doorgaan?",
        cancel: "Annuleren",
        confirm: "Bevestigen",
    },
    gameSeat: {
        hostSubtitle: "Hoofdspeler",
        guestSubtitle: "Gastspeler",
        youTag: "JIJ",
        emptyTitle: "Stoel is leeg",
        emptySubtitle: "Gast"
    },
    puzzleWaitingStartModal: {
        titleHostPov: "Wachten op spelers om deel te nemen",
        titleGuestPov: "Wachten tot de puzzel begint",
        titleError: "Fout bij deelname",
        puzzleIsFull: "Puzzel is vol",
        startGame: "Start spel",
        waitForStartAlert: "Wacht op de hoofdspeler om het spel te starten",
        startingIn: "Start in {{ secondsRemaining }} seconden"
    },
    puzzleWaitingAnswersModal: {
        title: "Wachten op andere spelers"
    },
    areYouStillThereModal: {
        title: "Speel je nog?",
        confirm: "Ja"
    },
    joinNewMemberModal: {
        title: "{{ name }} heeft je uitgenodigd om te spelen",
        input: {
            name: {
                label: "Voer je naam in"
            },
            email: {
                label: "Voer je e-mail in",
                error: "Voer een geldig e-mailadres in"
            }
        },
        submitButtonText: "Deelnemen"
    }
})

export default nlGeneral;
