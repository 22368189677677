import * as React from 'react';
import {useState} from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {useTranslation} from "react-i18next";
import {Alert, Button, Stack, TextField} from "@mui/material";
import {modalStyle} from "../style/modalStyle";
import {useRoomContext} from "../context/RoomContextProvider";

export default function JoinNewMemberModal({ hostName }) {
    const { joinRoomAsGuest, isGuest, myMemberId, joinRoomError } = useRoomContext();
    const { t } = useTranslation('joinNewMemberModal');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const onNameChange = (e) => {
        setName(e.target.value);
    }

    const onEmailChange = (e) => {
        const inputEmail = e.target.value;
        setEmail(inputEmail);

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (emailRegex.test(inputEmail)) {
            setEmailError(false);
        } else {
            setEmailError(true);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!emailError && email && name) {
            joinRoomAsGuest({ name, email });
        }
    }

    return (
            <Modal open={isGuest && !myMemberId}>
                <form onSubmit={handleSubmit}>
                    <Stack sx={modalStyle} gap={2}>
                        <Typography variant="h6" component="h2">
                            {t('title', { name: hostName })}
                        </Typography>
                        <TextField
                            onChange={onNameChange}
                            label={t('input.name.label')}
                            value={name}
                            variant="outlined"
                            required
                        />
                        <TextField
                            onChange={onEmailChange}
                            label={t('input.email.label')}
                            value={email}
                            variant="outlined"
                            required
                            error={emailError}
                            helperText={emailError ? t('input.email.error') : ""}
                        />
                        <Button
                            variant="contained"
                            type="submit"
                            onClick={handleSubmit}
                            disabled={!name || !email || emailError}
                            sx={{ marginTop: 1 }}
                        >
                            {t('submitButtonText')}
                        </Button>
                        {joinRoomError && (
                            <Alert severity="warning">
                                {joinRoomError}
                            </Alert>
                        )}
                    </Stack>
                </form>
            </Modal>
    );
}
