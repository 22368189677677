import { coreClient } from './config';

const versionPrefix = '/v2/game';

const gameRoomApiV2 = {
    getRoomDetails: (roomId, isGuest) => coreClient
            .get(`${versionPrefix}/rooms/${roomId}?isGuest=${isGuest}`)
            .then(({data}) => data),
};
export default gameRoomApiV2;
