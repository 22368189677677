import * as React from 'react';
import {
    Box, Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, InputLabel, MenuItem, Select
} from "@mui/material";
import {useTranslation} from "react-i18next";
import gameRoomApi from "../api/gameRoomApi";
import {useParams} from "react-router-dom";
import LangInput from "./navbar/LangInput";
import useSaveLanguageChange from "../hooks/useSaveLanguageChange";


export default function LangModal({open, callback}) {
    const {t, i18n} = useTranslation();
    const {roomId} = useParams();

    const onSubmit = useSaveLanguageChange({ roomId, callback })

    const handleLanguageChange = (event) => {
        i18n.changeLanguage(event.target.value);
    };

    return (
        <Dialog open={open}>
            <DialogTitle sx={{color: 'white'}}>
                {t('selectLanguage')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{color: 'white'}} component="div">
                    <Box mt={2}>
                        <LangInput onChange={handleLanguageChange}/>
                    </Box>
                </DialogContentText>
                <DialogActions>
                    {<Button fullWidth size="large" variant="contained" color="success" onClick={onSubmit}>
                        {t('go')}
                    </Button>}
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}
