import {FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Stack, Switch} from "@mui/material";
import * as React from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import useIsGuest from "../../hooks/useIsGuest";
import DifficultyPromptModal from "./DifficultyPromptModal";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DIFFICULTIES_OPTIONS} from "../../game/Game2";
import {usePuzzleContext} from "../../context/PuzzleContextProvider";
import useChangeDifficulty from "../../hooks/useChangeDifficulty";
import {useRoomContext} from "../../context/RoomContextProvider";
import gameApiV2 from "../../api/gameApiV2";
import {useRecoilState} from "recoil";
import {globalErrorState} from "../../recoil_state";

export default function TimerMessagesToggle() {
    const [checked, setChecked] = React.useState(true);
    const {isGuest} = useIsGuest();
    const {roomId, loading} = useRoomContext();
    const {puzzleStateResult} = usePuzzleContext();
    const { data } = puzzleStateResult;
    const { sendTimerMessages } = data ?? {}

    const [, setError] = useRecoilState(globalErrorState);

    const handleError = (err) => {
        setChecked(prev => !prev);
        if (err?.response?.data) {
            setError(err?.response?.data)
        }
    }

    const handleChange = (event) => {
        gameApiV2.toggleTimerMessages(roomId)
            .then()
            .catch(handleError)
        setChecked(event.target.checked);
    };

    useEffect(() => {
        setChecked(sendTimerMessages)
    }, [sendTimerMessages]);

    if (!puzzleStateResult.data) {
        return null;
    }

    return (
        <>
            <Stack sx={{ width: '100%' }} gap={1}>
                <FormGroup>
                    <FormControlLabel
                        disabled={isGuest}
                        control={<Switch
                            checked={checked}
                            onChange={handleChange}
                            sx={{
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                    color: 'red',
                                },
                                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                    backgroundColor: 'red',
                                },
                                '& .MuiSwitch-switchBase': {
                                    color: 'grey.500',
                                },
                                '& .MuiSwitch-track': {
                                    backgroundColor: 'grey.400',
                                },
                                // Dimmed styles for the disabled state
                                '& .MuiSwitch-switchBase.Mui-disabled.Mui-checked': {
                                    color: 'rgba(255, 0, 0, 0.4)',
                                },
                                '& .MuiSwitch-track.Mui-disabled.Mui-checked': {
                                    backgroundColor: 'rgba(255, 0, 0, 0.2)',
                                },
                                '& .MuiSwitch-switchBase.Mui-disabled': {
                                    color: 'rgba(128, 128, 128, 0.4)',
                                },
                                '& .MuiSwitch-track.Mui-disabled': {
                                    backgroundColor: 'rgba(128, 128, 128, 0.3)',
                                },
                            }}
                        />}
                        label="Hint messages"
                        labelPlacement="end"
                        sx={{
                            '&.Mui-disabled .MuiFormControlLabel-label': {
                                color: 'rgba(255, 255, 255, 0.16)'
                            },
                        }}
                    />
                </FormGroup>
            </Stack>
        </>
    )
}
