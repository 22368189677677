import useJoinRoom from "../hooks/useJoinRoom";
import useIsGuest from "../hooks/useIsGuest";
import {createContext, useContext, useEffect, useMemo} from "react";
import {useParams} from "react-router-dom";
import * as React from "react";

export const RoomContext = createContext({
});

export const useRoomContext = () => useContext(RoomContext);

const RoomContextProvider = ({children}) => {
    const {gameId: roomId} = useParams();
    const {isGuest, isHost} = useIsGuest();
    const {
        myMemberId,
        joinRoomAsGuest,
        joinRoomAsHost,
        loading,
        error,
    } = useJoinRoom();

    useEffect(() => {
        if (isHost && roomId) {
            joinRoomAsHost();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHost, roomId])

    const roomContextValues = useMemo(() => (
        {
            roomId,
            joinRoomAsGuest,
            joinRoomLoading: loading,
            joinRoomError: error,
            myMemberId,
            isGuest,
            isHost,
        }
    ), [
        roomId,
        joinRoomAsGuest,
        myMemberId,
        loading,
        error,
        isGuest,
    ]);

    return (
        <RoomContext.Provider value={roomContextValues}>
            {children}
        </RoomContext.Provider>
    )
}

export default RoomContextProvider;