import {useQuery} from "@tanstack/react-query";
import {QUERY_KEY} from "../constants/queryKeys";
import gameApiV2 from "../api/gameApiV2";
import {useEffect, useState} from "react";

const useJoinPuzzle = (roomId, puzzleId, memberId) => {
    const result = useQuery({
        queryKey: QUERY_KEY.joinPuzzle(roomId, puzzleId, memberId),
        queryFn: async () => {
            const response = await gameApiV2.joinPuzzle(roomId, puzzleId, memberId);
            if (!response) {
                throw new Error('Response is undefined or null');
            }
            return response;
        },
        enabled: !!roomId && !!puzzleId && !!memberId,
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
    })

    return ({
        ...result
    })
}

export default useJoinPuzzle;