import Paper from "@mui/material/Paper";
import {Stack, styled} from "@mui/material";
import background from "../../media/bg/chat.png";

const PaperSx = styled(Paper)`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  overflow: auto;
`

const AnswerDialogPaper = ({ children }) => {
    return (
        <PaperSx>
            <Stack
                sx={{
                    background: `repeat url(${background})`,
                    height: '100%',
                    backgroundPosition: 'center',
                    backgroundAttachment: 'fixed',
            }}
            >
            { children }
            </Stack>
        </PaperSx>
    )
}

export default AnswerDialogPaper;