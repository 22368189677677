import ListItemIcon from "@mui/material/ListItemIcon";
import PersonIcon from "@mui/icons-material/Person";
import ListItemText from "@mui/material/ListItemText";
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import * as React from "react";

const PlayerStat = ({ name, stat }) => {
    return (
        <ListItemButton sx={{pl: 4}}>
            <ListItemIcon>
                <PersonIcon/>
            </ListItemIcon>
            <ListItemText
                primary={(
                    <Stack direction="row" gap={1} alignItems="center" pr={6}>
                        <Typography fontSize={14} sx={{ width: '100%' }}>
                            {name}
                        </Typography>
                        <Stack width="100%"/>
                        <Typography fontSize={16} color="#FF5757" fontWeight="bold">
                            {stat}
                        </Typography>
                    </Stack>
                )}
            />
        </ListItemButton>
    )
}

export default PlayerStat;