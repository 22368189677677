import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import * as React from "react";
import {useTranslation} from "react-i18next";

export default function LangInput({ onChange, value }) {
    const {t, i18n} = useTranslation();
    return (
        <FormControl fullWidth>
            <InputLabel>{t('language')}</InputLabel>
            <Select
                id="language"
                value={value || i18n.language}
                label={t('language')}
                onChange={onChange}
            >
                {['en', 'nl'].map(k => [
                    <MenuItem key={k} value={k}>
                        {k === 'en' && t('languageEn')}
                        {k === 'nl' && t('languageNl')}
                    </MenuItem>
                ])}
            </Select>
        </FormControl>
    )
}