const enPuzzleParadise = (
    {
        'translate.the_knight_standard.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/1thumb.webp",
        'translate.the_knight_standard.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP1p2.webp",
        'translate.the_knight_standard.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP1p2.webp",
        'translate.the_knight_standard.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP1p1.webp",
        'translate.the_knight_standard.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP1p2.webp",

        'translate.the_knight_challenging.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP1p2.webp",
        'translate.the_knight_challenging.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP1p2.webp",
        'translate.the_knight_challenging.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP1p1.webp",
        'translate.the_knight_challenging.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP1p2.webp",

        'translate.the_blackout_standard.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/2thumb.webp",
        'translate.the_blackout_standard.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP2p1.webp",
        'translate.the_blackout_standard.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP2p2.webp",
        'translate.the_blackout_challenging.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/2thumb.webp",
        'translate.the_blackout_challenging.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP2p1.webp",
        'translate.the_blackout_challenging.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP2p2.webp",

        'translate.the_witnesses_standard.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/3thumb.webp",
        'translate.the_witnesses_standard.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP3b.webp",
        'translate.the_witnesses_standard.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP3p1.webp",
        'translate.the_witnesses_standard.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP3b.webp",
        'translate.the_witnesses_standard.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP3p2.webp",
        'translate.the_witnesses_challenging.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/3thumb.webp",
        'translate.the_witnesses_challenging.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP3b.webp",
        'translate.the_witnesses_challenging.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP3p1.webp",
        'translate.the_witnesses_challenging.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP3b.webp",
        'translate.the_witnesses_challenging.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP3p2.webp",

        'translate.the_key_standard.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/4thumb.webp",
        'translate.the_key_standard.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP4b.webp",
        'translate.the_key_standard.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP4b.webp",
        'translate.the_key_challenging.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/4thumb.webp",
        'translate.the_key_challenging.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP4b.webp",
        'translate.the_key_challenging.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP4b.webp",

        'translate.the_interrogation_standard.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/5thumb.webp",
        'translate.the_interrogation_standard.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP5b.webp",
        'translate.the_interrogation_standard.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP5b.webp",
        'translate.the_interrogation_standard.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP5p1.webp",
        'translate.the_interrogation_standard.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP5p2.webp",
        'translate.the_interrogation_challenging.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/5thumb.webp",
        'translate.the_interrogation_challenging.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP5b.webp",
        'translate.the_interrogation_challenging.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP5b.webp",
        'translate.the_interrogation_challenging.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP5p1.webp",
        'translate.the_interrogation_challenging.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP5p2.webp",


        'translate.the_card_game_standard.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/6thumb.webp",
        'translate.the_card_game_standard.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6b.webp",
        'translate.the_card_game_standard.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p1a.webp",
        'translate.the_card_game_standard.player.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p1b.webp",
        'translate.the_card_game_standard.player.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p1c.webp",
        'translate.the_card_game_standard.player.media5': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p1d.webp",
        'translate.the_card_game_standard.player.media6': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p1e.webp",
        'translate.the_card_game_standard.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6b.webp",
        'translate.the_card_game_standard.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p2v.webp",
        'translate.the_card_game_standard.guest.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p2w.webp",
        'translate.the_card_game_standard.guest.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p2x.webp",
        'translate.the_card_game_standard.guest.media5': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p2y.webp",
        'translate.the_card_game_standard.guest.media6': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p2z.webp",

        'translate.the_card_game_challenging.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/6thumb.webp",
        'translate.the_card_game_challenging.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6b.webp",
        'translate.the_card_game_challenging.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p1a.webp",
        'translate.the_card_game_challenging.player.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p1b.webp",
        'translate.the_card_game_challenging.player.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p1c.webp",
        'translate.the_card_game_challenging.player.media5': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p1d.webp",
        'translate.the_card_game_challenging.player.media6': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p1e.webp",
        'translate.the_card_game_challenging.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6b.webp",
        'translate.the_card_game_challenging.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p2v.webp",
        'translate.the_card_game_challenging.guest.media3': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p2w.webp",
        'translate.the_card_game_challenging.guest.media4': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p2x.webp",
        'translate.the_card_game_challenging.guest.media5': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p2y.webp",
        'translate.the_card_game_challenging.guest.media6': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP6p2z.webp",

        'translate.the_missing_items_standard.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/7thumb.webp",
        'translate.the_missing_items_standard.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP7p1.webp",
        'translate.the_missing_items_standard.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP7p2.webp",
        'translate.the_missing_items_challenging.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/7thumb.webp",
        'translate.the_missing_items_challenging.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP7p1.webp",
        'translate.the_missing_items_challenging.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP7p2.webp",

        'translate.the_traces_standard.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/8thumb.webp",
        'translate.the_traces_standard.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP8p1.webp",
        'translate.the_traces_standard.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP8p2.webp",
        'translate.the_traces_challenging.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/8thumb.webp",
        'translate.the_traces_challenging.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP8p1.webp",
        'translate.the_traces_challenging.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP8p2.webp",

        'translate.the_security_tapes_standard.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/9thumb.webp",
        'translate.the_security_tapes_standard.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP9b.webp",
        'translate.the_security_tapes_standard.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP9p1.webp",
        'translate.the_security_tapes_standard.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP9b.webp",
        'translate.the_security_tapes_standard.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP9p2.webp",
        'translate.the_security_tapes_challenging.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/9thumb.webp",
        'translate.the_security_tapes_challenging.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP9b.webp",
        'translate.the_security_tapes_challenging.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP9p1.webp",
        'translate.the_security_tapes_challenging.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP9b.webp",
        'translate.the_security_tapes_challenging.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP9p2.webp",

        'translate.whodunnit_standard.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/10thumb.webp",
        'translate.whodunnit_standard.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP10b.webp",
        'translate.whodunnit_standard.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP10b.webp",
        'translate.whodunnit_challenging.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP10b.webp",
        'translate.whodunnit_challenging.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACIP10b.webp",

        'translate.game.paradise.puzzle_name.intro1': "Intro 1",
        'translate.game.paradise.puzzle_name.intro2': "Intro 2",
        'translate.game.paradise.puzzle_name.the_knight': "The Knight",
        'translate.game.paradise.puzzle_name.the_blackout': "The Blackout",
        'translate.game.paradise.puzzle_name.the_witnesses': "The Witnesses",
        'translate.game.paradise.puzzle_name.the_key': "The Key",
        'translate.game.paradise.puzzle_name.the_interrogation': "The Interrogation",
        'translate.game.paradise.puzzle_name.the_card_game': "The Card Game",
        'translate.game.paradise.puzzle_name.the_missing_items': "The Missing Items",
        'translate.game.paradise.puzzle_name.the_traces': "The Traces",
        'translate.game.paradise.puzzle_name.the_security_tapes': "The Security Tapes",
        'translate.game.paradise.puzzle_name.whodunnit': "Whodunnit",

            'translate.the_knight_standard.timer.title': "1. The Knight - A Case In Paradise",
            'translate.the_knight_standard.timer.subtitle': "The challenge will start in:",
            'translate.the_knight_standard.message1.title': "Let’s figure out what happened here. We need to compare how it looks now with the camera recordings from yesterday. I wrote down the items. {{playerName}}, can you find which items on your list were moved?",
            'translate.the_knight_standard.message2.title': "Ask {{partnerName}} for the letters of their items and let me know what changed.",
            'translate.the_knight_standard.message2.error': "That doesn't match what I'm seeing here. We need to look again. I think five items have been moved.",
            'translate.the_knight_standard.message3.title': "Thank you. I will try to make sense of what happened here.",
            'translate.the_knight_standard.player.timer.message1': 'There are five items that have been moved.',
            'translate.the_knight_standard.player.timer.message2': 'The camera that shows the statue with a hat, two items can be seen that are moved. One item is moved on the camera position that shows the chessboard and two items have been moved on the camera position that shows the chair.',
            'translate.the_knight_standard.player.hint.explanation': "You need to compare the security camera footage with the footage of Max moving around the room. There are four areas. Try to see if something changed in an area and if so, what changed. You will need to give the same answer, so ask the other player for their items. You can help them to see if something changed.",
            'translate.the_knight_standard.player.hint.hint1': "There are five items that have been moved.",
            'translate.the_knight_standard.player.hint.hint2': "The camera that shows the statue with a hat, two items can be seen that are moved. One item is moved on the camera position that shows the chessboard and two items have been moved on the camera position that shows the chair.",
            'translate.the_knight_standard.player.hint.solution': "B F G M S. Five things have changed, bowl, candle holder, vase, book and rug. On the camera position that shows that statue with the hat, you can see that the bowl has fallen on the floor and that the candle holder has been turned around). On the camera position with the chessboard, you can see the top of the vase has been moved to another leg of the table. On the camera position with the chair, you see the book is flat instead of standing up and the rug has moved.",

            'translate.the_knight_standard.guest_message1.title': "Let’s figure out what happened here. We need to compare how it looks now with the camera recordings from yesterday. I wrote down the items. {{playerName}}, can you find which items on your list were moved?",
            'translate.the_knight_standard.guest_message2.title': "Ask {{partnerName}} for the letters of their items and let me know what changed.",
            'translate.the_knight_standard.guest_message2.error': "That doesn't match what I'm seeing here. We need to look again. I think five items have been moved.",
            'translate.the_knight_standard.guest_message3.title': "Thank you. I will try to make sense of what happened here.",
            'translate.the_knight_standard.guest.timer.message1': 'There are five items that have been moved.',
            'translate.the_knight_standard.guest.timer.message2': 'The camera that shows the statue with a hat, two items can be seen that are moved. One item is moved on the camera position that shows the chessboard and two items have been moved on the camera position that shows the chair.',
            'translate.the_knight_standard.guest.hint.explanation': "You need to compare the security camera footage with the footage of Max moving around the room. There are four areas. Try to see if something changed in an area and if so, what changed. You will need to give the same answer, so ask the other player for their items. You can help them to see if something changed.",
            'translate.the_knight_standard.guest.hint.hint1': "There are five items that have been moved.",
            'translate.the_knight_standard.guest.hint.hint2': "The camera that shows the statue with a hat, two items can be seen that are moved. One item is moved on the camera position that shows the chessboard and two items have been moved on the camera position that shows the chair.",
            'translate.the_knight_standard.guest.hint.solution': "B F G M S. Five things have changed, bowl, candle holder, vase, book and rug. On the camera position that shows that statue with the hat, you can see that the bowl has fallen on the floor and that the candle holder has been turned around). On the camera position with the chessboard, you can see the top of the vase has been moved to another leg of the table. On the camera position with the chair, you see the book is flat instead of standing up and the rug has moved.",

            'translate.the_knight_challenging.timer.title': "1. The Knight - A Case In Paradise",
            'translate.the_knight_challenging.timer.subtitle': "The challenge will start in:",
            'translate.the_knight_challenging.message1.title': "Let’s figure out what happened here. I wrote down the items. {{playerName}}, can you find which items changed on your list).",
            'translate.the_knight_challenging.message2.title': "Ask {{partnerName}}for the numbers of their items and let me know what changed.",
            'translate.the_knight_challenging.message2.error': "That doesn't match what I'm seeing here. We need to look again. I think five items have been moved.",
            'translate.the_knight_challenging.message3.title': "Thank you. I will try to make sense of what happened here.",
            'translate.the_knight_challenging.player.timer.message1': 'There are five items that have been moved.',
            'translate.the_knight_challenging.player.timer.message2': 'The camera that shows the statue with a hat, two items can be seen that are moved. One item is moved on the camera position that shows the chessboard and two items have been moved on the camera position that shows the chair.',
            'translate.the_knight_challenging.player.hint.explanation': "You need to compare the security camera footage with the footage of Max moving around the room. There are four areas. Try to see if something changed in an area and if so, what changed. You will need to give the same answer, so ask the other player for their items. You can help them to see if something changed.",
            'translate.the_knight_challenging.player.hint.hint1': "There are five items that have been moved.",
            'translate.the_knight_challenging.player.hint.hint2': "The camera that shows the statue with a hat, two items can be seen that are moved. One item is moved on the camera position that shows the chessboard and two items have been moved on the camera position that shows the chair.",
            'translate.the_knight_challenging.player.hint.solution': "B F G M S. Five things have changed, bowl, candle holder, vase, book and rug. On the camera position that shows that statue with the hat, you can see that the bowl has fallen on the floor and that the candle holder has been turned around). On the camera position with the chessboard, you can see the top of the vase has been moved to another leg of the table. On the camera position with the chair, you see the book is flat instead of standing up and the rug has moved.",

            'translate.the_knight_challenging.guest_message1.title': "Let’s figure out what happened here. I wrote down the items. {{playerName}}, can you find which items changed on your list).",
            'translate.the_knight_challenging.guest_message2.title': "Ask {{partnerName}}for the numbers of their items and let me know what changed.",
            'translate.the_knight_challenging.guest_message2.error': "That doesn't match what I'm seeing here. We need to look again. I think five items have been moved.",
            'translate.the_knight_challenging.guest_message3.title': "Thank you. I will try to make sense of what happened here.",
            'translate.the_knight_challenging.guest.timer.message1': 'There are five items that have been moved.',
            'translate.the_knight_challenging.guest.timer.message2': 'The camera that shows the statue with a hat, two items can be seen that are moved. One item is moved on the camera position that shows the chessboard and two items have been moved on the camera position that shows the chair.',
            'translate.the_knight_challenging.guest.hint.explanation': "You need to compare the security camera footage with the footage of Max moving around the room. There are four areas. Try to see if something changed in an area and if so, what changed. You will need to give the same answer, so ask the other player for their items. You can help them to see if something changed.",
            'translate.the_knight_challenging.guest.hint.hint1': "There are five items that have been moved.",
            'translate.the_knight_challenging.guest.hint.hint2': "The camera that shows the statue with a hat, two items can be seen that are moved. One item is moved on the camera position that shows the chessboard and two items have been moved on the camera position that shows the chair.",
            'translate.the_knight_challenging.guest.hint.solution': "B F G M S. Five things have changed, bowl, candle holder, vase, book and rug. On the camera position that shows that statue with the hat, you can see that the bowl has fallen on the floor and that the candle holder has been turned around). On the camera position with the chessboard, you can see the top of the vase has been moved to another leg of the table. On the camera position with the chair, you see the book is flat instead of standing up and the rug has moved.",

            'translate.the_blackout_standard.timer.title': "2. The Blackout - A Case in Paradise",
            'translate.the_blackout_standard.timer.subtitle': "The challenge will start in:",
            'translate.the_blackout_standard.message1.title': "So the blackout disabled the power to the alarm… Apparently, if all lights and fans are on, it causes a blackout. The owner sent me some complaints from the guests with his notes on them. Lovely man.",
            'translate.the_blackout_standard.message2.title': "Let’s see if we can recreate this blackout. All lights and fans are turned off now, so let me know what the easiest way is to turn them all on. You will probably want to make some notes yourself as well.",
            'translate.the_blackout_standard.message2.error': "I think we need to check again. I suspect only 3 actions are needed to create a blackout. Can you check again for me, using the TAG ANSWER link above?",
            'translate.the_blackout_standard.message3.title': "That makes sense. Now let's see if this actually causes a blackout.",
            'translate.the_blackout_standard.player.timer.message1': 'There are only 3 actions needed to recreate this blackout.',
            'translate.the_blackout_standard.player.timer.message2': 'You only need to use two of the remotes and one switch to cause a blackout.',
            'translate.the_blackout_standard.player.hint.explanation': "There are three rooms (bar, lobby, hallway) and there is a lightswitch and a remote in each room. So there are 6 actions that can be taken. You need to notice the effects of each action and then figure out how to create the blackout. Focus first on finding out how to turn on the fan in the hallway.",
            'translate.the_blackout_standard.player.hint.hint1': "There are only 3 actions needed to recreate this blackout.",
            'translate.the_blackout_standard.player.hint.hint2': "You only need to use two of the remotes and one switch to cause a blackout.",
            'translate.the_blackout_standard.player.hint.solution': "You can create a blackout using the switch in the hallway, the remote in the lobby and the remote in the bar. The overview of all effects is this: Bar Fan and Hall Light (Hallway switch)Bar Fan and Lobby Fan (Hallway remote)Bar Light and Hall Light (Lobby switch)Bar Light and Lobby Fan (Bar switch)Bar Light and Lobby Light (Bar remote)Lobby Fan and Hall Fan (Lobby remote)Using the remote in the lobby is the only way to turn the fan in the hall on. This also has the effect of turning the fan in the lobby on, which means that you cannot use the hallway remote or the switch in the bar (it would turn the fan in the lobby back off). Now only one way remains to turn on the fan in the bar (hallway switch), and after that you are left with the light in the lobby and the light in the bar which matches the bar remote.",
            'translate.the_blackout_standard.guest_message1.title': "So the blackout disabled the power to the alarm… Apparently, if all lights and fans are on, it causes a blackout. The owner sent me some complaints from the guests with his notes on them. Lovely man.",
            'translate.the_blackout_standard.guest_message2.title': "Let’s see if we can recreate this blackout. All lights and fans are turned off now, so let me know what the easiest way is to turn them all on. You will probably want to make some notes yourself as well.",
            'translate.the_blackout_standard.guest_message2.error': "I think we need to check again. I suspect only 3 actions are needed to create a blackout. Can you check again for me, using the TAG ANSWER link above?",
            'translate.the_blackout_standard.guest_message3.title': "That makes sense. Now let's see if this actually causes a blackout.",
            'translate.the_blackout_standard.guest.timer.message1': 'There are only 3 actions needed to recreate this blackout.',
            'translate.the_blackout_standard.guest.timer.message2': 'You only need to use two of the remotes and one switch to cause a blackout.',
            'translate.the_blackout_standard.guest.hint.explanation': "There are three rooms (bar, lobby, hallway) and there is a lightswitch and a remote in each room. So there are 6 actions that can be taken. You need to notice the effects of each action and then figure out how to create the blackout. Focus first on finding out how to turn on the fan in the hallway.",
            'translate.the_blackout_standard.guest.hint.hint1': "There are only 3 actions needed to recreate this blackout.",
            'translate.the_blackout_standard.guest.hint.hint2': "You only need to use two of the remotes and one switch to cause a blackout.",
            'translate.the_blackout_standard.guest.hint.solution': "You can create a blackout using the switch in the hallway, the remote in the lobby and the remote in the bar. The overview of all effects is this: Bar Fan and Hall Light (Hallway switch)Bar Fan and Lobby Fan (Hallway remote)Bar Light and Hall Light (Lobby switch)Bar Light and Lobby Fan (Bar switch)Bar Light and Lobby Light (Bar remote)Lobby Fan and Hall Fan (Lobby remote)Using the remote in the lobby is the only way to turn the fan in the hall on. This also has the effect of turning the fan in the lobby on, which means that you cannot use the hallway remote or the switch in the bar (it would turn the fan in the lobby back off). Now only one way remains to turn on the fan in the bar (hallway switch), and after that you are left with the light in the lobby and the light in the bar which matches the bar remote.",

            'translate.the_blackout_challenging.timer.title': "2. The Blackout - A Case in Paradise",
            'translate.the_blackout_challenging.timer.subtitle': "The challenge will start in:",
            'translate.the_blackout_challenging.message1.title': "So the blackout disabled the power to the alarm… Apparently, if all lights and fans are on, it causes a blackout. The owner sent me some complaints from the guests with his notes on them. Lovely man.",
            'translate.the_blackout_challenging.message2.title': "Let’s see if we can recreate this blackout. All lights and fans are turned off now, so let me know what the easiest way is to turn them all on. You will probably want to make some notes yourself as well.",
            'translate.the_blackout_challenging.message2.error': "I think we need to check again. I suspect only 3 actions are needed to create a blackout. Can you check again for me, using the TAG ANSWER link above?",
            'translate.the_blackout_challenging.message3.title': "That makes sense. Now let's see if this actually causes a blackout.",
            'translate.the_blackout_challenging.player.timer.message1': 'There are only 3 actions needed to recreate this blackout.',
            'translate.the_blackout_challenging.player.timer.message2': 'You only need to use two of the remotes and one switch to cause a blackout.',
            'translate.the_blackout_challenging.player.hint.explanation': "There are three rooms (bar, lobby, hallway) and there is a lightswitch and a remote in each room. So there are 6 actions that can be taken. You need to notice the effects of each action and then figure out how to create the blackout. Focus first on finding out how to turn on the fan in the hallway.",
            'translate.the_blackout_challenging.player.hint.hint1': "There are only 3 actions needed to recreate this blackout.",
            'translate.the_blackout_challenging.player.hint.hint2': "You only need to use two of the remotes and one switch to cause a blackout.",
            'translate.the_blackout_challenging.player.hint.solution': "You can create a blackout using the switch in the hallway, the remote in the lobby and the remote in the bar. The overview of all effects is this: Bar Fan and Hall Light (Hallway switch)Bar Fan and Lobby Fan (Hallway remote)Bar Light and Hall Light (Lobby switch)Bar Light and Lobby Fan (Bar switch)Bar Light and Lobby Light (Bar remote)Lobby Fan and Hall Fan (Lobby remote)Using the remote in the lobby is the only way to turn the fan in the hall on. This also has the effect of turning the fan in the lobby on, which means that you cannot use the hallway remote or the switch in the bar (it would turn the fan in the lobby back off). Now only one way remains to turn on the fan in the bar (hallway switch), and after that you are left with the light in the lobby and the light in the bar which matches the bar remote.",
            'translate.the_blackout_challenging.guest_message1.title': "So the blackout disabled the power to the alarm… Apparently, if all lights and fans are on, it causes a blackout. The owner sent me some complaints from the guests with his notes on them. Lovely man.",
            'translate.the_blackout_challenging.guest_message2.title': "Let’s see if we can recreate this blackout. All lights and fans are turned off now, so let me know what the easiest way is to turn them all on. You will probably want to make some notes yourself as well.",
            'translate.the_blackout_challenging.guest_message2.error': "I think we need to check again. I suspect only 3 actions are needed to create a blackout. Can you check again for me, using the TAG ANSWER link above?",
            'translate.the_blackout_challenging.guest_message3.title': "That makes sense. Now let's see if this actually causes a blackout.",
            'translate.the_blackout_challenging.guest.timer.message1': 'There are only 3 actions needed to recreate this blackout.',
            'translate.the_blackout_challenging.guest.timer.message2': 'You only need to use two of the remotes and one switch to cause a blackout.',
            'translate.the_blackout_challenging.guest.hint.explanation': "There are three rooms (bar, lobby, hallway) and there is a lightswitch and a remote in each room. So there are 6 actions that can be taken. You need to notice the effects of each action and then figure out how to create the blackout. Focus first on finding out how to turn on the fan in the hallway.",
            'translate.the_blackout_challenging.guest.hint.hint1': "There are only 3 actions needed to recreate this blackout.",
            'translate.the_blackout_challenging.guest.hint.hint2': "You only need to use two of the remotes and one switch to cause a blackout.",
            'translate.the_blackout_challenging.guest.hint.solution': "You can create a blackout using the switch in the hallway, the remote in the lobby and the remote in the bar. The overview of all effects is this: Bar Fan and Hall Light (Hallway switch)Bar Fan and Lobby Fan (Hallway remote)Bar Light and Hall Light (Lobby switch)Bar Light and Lobby Fan (Bar switch)Bar Light and Lobby Light (Bar remote)Lobby Fan and Hall Fan (Lobby remote)Using the remote in the lobby is the only way to turn the fan in the hall on. This also has the effect of turning the fan in the lobby on, which means that you cannot use the hallway remote or the switch in the bar (it would turn the fan in the lobby back off). Now only one way remains to turn on the fan in the bar (hallway switch), and after that you are left with the light in the lobby and the light in the bar which matches the bar remote.",

            'translate.the_witnesses_standard.timer.title': "3. The Witnesses - A Case in Paradise",
            'translate.the_witnesses_standard.timer.subtitle': "The challenge will start in:",
            'translate.the_witnesses_standard.message1.title': "These witnesses claim to be in their room during the blackout. Let’s see if their stories check out.",
            'translate.the_witnesses_standard.message2.title': "Place the names in the order of the rooms (please note, this way of entering a solution is work in progress and for this test only).",
            'translate.the_witnesses_standard.message2.error': "That doesn’t completely match with their statements. Please check it again using the TAG ANSWER link above.",
            'translate.the_witnesses_standard.message3.title': "That makes sense. This means that each of these witnesses had an alibi during the blackout. Let’s meet the other guests that were present that night.",
            'translate.the_witnesses_standard.player.timer.message1': 'Justin is in room 2.',
            'translate.the_witnesses_standard.player.timer.message2': 'Room 3 and room 5 are unknown',
            'translate.the_witnesses_standard.player.hint.explanation': "You need to place each guest in the right room. Start with Anna Marquez who is staying in room 1 and focus first on figuring out which people are on the top or bottom floor.",
            'translate.the_witnesses_standard.player.hint.hint1': "Justin is in room 2.",
            'translate.the_witnesses_standard.player.hint.hint2': "Room 3 and room 5 are unknown.",
            'translate.the_witnesses_standard.player.hint.solution': "1: Daniel. 2 Justin. 3: Unknown. 4: Gabrielle. 5. Unknown 6. Thomas. 7. Jane. 8. Liz. Anna (last surname as Daniel) tells you that she is in room 201 and that there is laundry room on the top floor where machines made a lot of noise and someone was cursing (this must be Justin). Daniel says he he heard no one below him, that his wife is in the room with him and that she makes noise. Justin hears yelling in spanish in the next room, so he is next door (in 2). Gabrielle didn’t hear anything next or below her, so she must be in room (4). Liz hears an opera singer, so she is below Gabrielle (8).Thomas makes noise and hears noise above him, so he can only be below Justin (Daniel would have heard him otherwise) in room 6. Jane hears nothing in the room above,  she must be in 7, if she was in 5 she would have hear arguing in Spanish.",
            'translate.the_witnesses_standard.guest_message1.title': "These witnesses claim to be in their room during the blackout. Let’s see if their stories check out.",
            'translate.the_witnesses_standard.guest_message2.title': "Place the names in the order of the rooms (please note, this way of entering a solution is work in progress and for this test only).",
            'translate.the_witnesses_standard.guest_message2.error': "That doesn’t completely match with their statements. Please check it again using the TAG ANSWER link above.",
            'translate.the_witnesses_standard.guest_message3.title': "That makes sense. This means that each of these witnesses had an alibi during the blackout. Let’s meet the other guests that were present that night.",
            'translate.the_witnesses_standard.guest.timer.message1': 'Justin is in room 2.',
            'translate.the_witnesses_standard.guest.timer.message2': 'Room 3 and room 5 are unknown',
            'translate.the_witnesses_standard.guest.hint.explanation': "You need to place each guest in the right room. Start with Anna Marquez who is staying in room 1 and focus first on figuring out which people are on the top or bottom floor. ",
            'translate.the_witnesses_standard.guest.hint.hint1': "Justin is in room 2.",
            'translate.the_witnesses_standard.guest.hint.hint2': "Room 3 and room 5 are unknown.",
            'translate.the_witnesses_standard.guest.hint.solution': "1: Daniel. 2 Justin. 3: Unknown. 4: Gabrielle. 5. Unknown 6. Thomas. 7. Jane. 8. Liz. Anna (last surname as Daniel) tells you that she is in room 201 and that there is laundry room on the top floor where machines made a lot of noise and someone was cursing (this must be Justin). Daniel says he he heard no one below him, that his wife is in the room with him and that she makes noise. Justin hears yelling in spanish in the next room, so he is next door (in 2). Gabrielle didn’t hear anything next or below her, so she must be in room (4). Liz hears an opera singer, so she is below Gabrielle (8).Thomas makes noise and hears noise above him, so he can only be below Justin (Daniel would have heard him otherwise) in room 6. Jane hears nothing in the room above,  she must be in 7, if she was in 5 she would have hear arguing in Spanish.",

            'translate.the_witnesses_challenging.timer.title': "3. The Witnesses - A Case in Paradise",
            'translate.the_witnesses_challenging.timer.subtitle': "The challenge will start in:",
            'translate.the_witnesses_challenging.message1.title': "These witnesses claim to be in their room during the blackout. Let’s see if their stories check out.",
            'translate.the_witnesses_challenging.message2.title': "Place the names in the order of the rooms (please note, this way of entering a solution is work in progress and for this test only).",
            'translate.the_witnesses_challenging.message2.error': "That doesn’t completely match with their statements. Please check it again using the TAG ANSWER link above.",
            'translate.the_witnesses_challenging.message3.title': "That makes sense. This means that each of these witnesses had an alibi during the blackout. Let’s meet the other guests that were present that night.",
            'translate.the_witnesses_challenging.player.timer.message1': 'Justin is in room 2.',
            'translate.the_witnesses_challenging.player.timer.message2': 'Room 3 and room 5 are unknown',
            'translate.the_witnesses_challenging.player.hint.explanation': "You need to place each guest in the right room. Start with Anna Marquez who is staying in room 1 and focus first on figuring out which people are on the top or bottom floor.",
            'translate.the_witnesses_challenging.player.hint.hint1': "Justin is in room 2.",
            'translate.the_witnesses_challenging.player.hint.hint2': "Room 3 and room 5 are unknown.",
            'translate.the_witnesses_challenging.player.hint.solution': "1: Daniel. 2 Justin. 3: Unknown. 4: Gabrielle. 5. Unknown 6. Thomas. 7. Jane. 8. Liz. Anna (last surname as Daniel) tells you that she is in room 201 and that there is laundry room on the top floor where machines made a lot of noise and someone was cursing (this must be Justin). Daniel says he he heard no one below him, that his wife is in the room with him and that she makes noise. Justin hears yelling in spanish in the next room, so he is next door (in 2). Gabrielle didn’t hear anything next or below her, so she must be in room (4). Liz hears an opera singer, so she is below Gabrielle (8).Thomas makes noise and hears noise above him, so he can only be below Justin (Daniel would have heard him otherwise) in room 6. Jane hears nothing in the room above,  she must be in 7, if she was in 5 she would have hear arguing in Spanish.",
            'translate.the_witnesses_challenging.guest_message1.title': "These witnesses claim to be in their room during the blackout. Let’s see if their stories check out.",
            'translate.the_witnesses_challenging.guest_message2.title': "Place the names in the order of the rooms (please note, this way of entering a solution is work in progress and for this test only).",
            'translate.the_witnesses_challenging.guest_message2.error': "That doesn’t completely match with their statements. Please check it again using the TAG ANSWER link above.",
            'translate.the_witnesses_challenging.guest_message3.title': "That makes sense. This means that each of these witnesses had an alibi during the blackout. Let’s meet the other guests that were present that night.",
            'translate.the_witnesses_challenging.guest.timer.message1': 'Justin is in room 2.',
            'translate.the_witnesses_challenging.guest.timer.message2': 'Room 3 and room 5 are unknown',
            'translate.the_witnesses_challenging.guest.hint.explanation': "You need to place each guest in the right room. Start with Anna Marquez who is staying in room 1 and focus first on figuring out which people are on the top or bottom floor. ",
            'translate.the_witnesses_challenging.guest.hint.hint1': "Justin is in room 2.",
            'translate.the_witnesses_challenging.guest.hint.hint2': "Room 3 and room 5 are unknown.",
            'translate.the_witnesses_challenging.guest.hint.solution': "1: Daniel. 2 Justin. 3: Unknown. 4: Gabrielle. 5. Unknown 6. Thomas. 7. Jane. 8. Liz. Anna (last surname as Daniel) tells you that she is in room 201 and that there is laundry room on the top floor where machines made a lot of noise and someone was cursing (this must be Justin). Daniel says he he heard no one below him, that his wife is in the room with him and that she makes noise. Justin hears yelling in spanish in the next room, so he is next door (in 2). Gabrielle didn’t hear anything next or below her, so she must be in room (4). Liz hears an opera singer, so she is below Gabrielle (8).Thomas makes noise and hears noise above him, so he can only be below Justin (Daniel would have heard him otherwise) in room 6. Jane hears nothing in the room above,  she must be in 7, if she was in 5 she would have hear arguing in Spanish.",

            'translate.the_key_standard.timer.title': "4. The Key - A Case in Paradise",
            'translate.the_key_standard.timer.subtitle': "The challenge will start in:",
            'translate.the_key_standard.message1.title': "Well, now we know why the door was open. Someone took the spare key. Let’s see what he remembers about that night.",
            'translate.the_key_standard.message2.title': "Who had their hands on the key? And in what order?",
            'translate.the_key_standard.message2.error': "I think it happened in a different way. The way he tells it, I think someone had the key twice. Use the TAG ANSWER link above and let me know.",
            'translate.the_key_standard.message3.title': "That matches his story. So the Lady took it... Let’s hear what she has to say.",
            'translate.the_key_standard.player.timer.message1': 'The key changed hands 4 times, so you’ll have to enter 5 names.',
            'translate.the_key_standard.player.timer.message2': 'The lady took the bottle opener twice. The Boy is the only one who never had the key.',
            'translate.the_key_standard.player.hint.explanation': "You need to look closely at the video. Who took the bottle opener in the beginning and who had it in the end. You might not always see the opener, so sometimes you will have to deduct who might have taken it in the meantime.",
            'translate.the_key_standard.player.hint.hint1': "The key changed hands 4 times, so you’ll have to enter 5 names.",
            'translate.the_key_standard.player.hint.hint2': "The lady took the bottle opener twice. The Boy is the only one who never had the key.",
            'translate.the_key_standard.player.hint.solution': "Lady -  Caregiver - Baron - Peach - Lady. In the beginning of the scene you see the lady trying to open the bottle of the Boy. She messes up and leaves the key on the counter for the Caregiver to take it. You see the baron move towards the hands of the  Caregiver and then later you see Peach having the opener. Peach did not take it from the  Caregiver, so she would have taken it from the Baron. After Peach drops the opener, both Peach and the Lady drop to the floor, after which Peach shows empty hands.",
            'translate.the_key_standard.guest_message1.title': "Well, now we know why the door was open. Someone took the spare key. Let’s see what he remembers about that night.",
            'translate.the_key_standard.guest_message2.title': "Who had their hands on the key? And in what order?",
            'translate.the_key_standard.guest_message2.error': "I think it happened in a different way. The way he tells it, I think someone had the key twice. Use the TAG ANSWER link above and let me know.",
            'translate.the_key_standard.guest_message3.title': "That matches his story. So the Lady took it... Let’s hear what she has to say.",
            'translate.the_key_standard.guest.timer.message1': 'The key changed hands 4 times, so you’ll have to enter 5 names.',
            'translate.the_key_standard.guest.timer.message2': 'The lady took the bottle opener twice. The Boy is the only one who never had the key.',
            'translate.the_key_standard.guest.hint.explanation': "You need to look closely at the video. Who took the bottle opener in the beginning and who had it in the end. You might not always see the opener, so sometimes you will have to deduct who might have taken it in the meantime.",
            'translate.the_key_standard.guest.hint.hint1': "The key changed hands 4 times, so you’ll have to enter 5 names.",
            'translate.the_key_standard.guest.hint.hint2': "The lady took the bottle opener twice. The Boy is the only one who never had the key.",
            'translate.the_key_standard.guest.hint.solution': "Lady -  Caregiver - Baron - Peach - Lady. In the beginning of the scene you see the lady trying to open the bottle of the Boy. She messes up and leaves the key on the counter for the Caregiver to take it. You see the baron move towards the hands of the  Caregiver and then later you see Peach having the opener. Peach did not take it from the  Caregiver, so she would have taken it from the Baron. After Peach drops the opener, both Peach and the Lady drop to the floor, after which Peach shows empty hands.",

            'translate.the_key_challenging.timer.title': "4. The Key - A Case in Paradise",
            'translate.the_key_challenging.timer.subtitle': "The challenge will start in:",
            'translate.the_key_challenging.message1.title': "Well, now we know why the door was open. Someone took the spare key. Let’s see what he remembers about that night.",
            'translate.the_key_challenging.message2.title': "Who had their hands on the key? And in what order?",
            'translate.the_key_challenging.message2.error': "I think it happened in a different way. The way he tells it, I think someone had the key twice. Use the TAG ANSWER link above and let me know.",
            'translate.the_key_challenging.message3.title': "That matches his story. So the Lady took it... Let’s hear what she has to say.",
            'translate.the_key_challenging.player.timer.message1': 'The key changed hands 4 times, so you’ll have to enter 5 names.',
            'translate.the_key_challenging.player.timer.message2': 'The lady took the bottle opener twice. The Boy is the only one who never had the key.',
            'translate.the_key_challenging.player.hint.explanation': "You need to look closely at the video. Who took the bottle opener in the beginning and who had it in the end. You might not always see the opener, so sometimes you will have to deduct who might have taken it in the meantime.",
            'translate.the_key_challenging.player.hint.hint1': "The key changed hands 4 times, so you’ll have to enter 5 names.",
            'translate.the_key_challenging.player.hint.hint2': "The lady took the bottle opener twice. The Boy is the only one who never had the key.",
            'translate.the_key_challenging.player.hint.solution': "Lady -  Caregiver - Baron - Peach - Lady. In the beginning of the scene you see the lady trying to open the bottle of the Boy. She messes up and leaves the key on the counter for the Caregiver to take it. You see the baron move towards the hands of the  Caregiver and then later you see Peach having the opener. Peach did not take it from the  Caregiver, so she would have taken it from the Baron. After Peach drops the opener, both Peach and the Lady drop to the floor, after which Peach shows empty hands.",
            'translate.the_key_challenging.guest_message1.title': "Well, now we know why the door was open. Someone took the spare key. Let’s see what he remembers about that night.",
            'translate.the_key_challenging.guest_message2.title': "Who had their hands on the key? And in what order?",
            'translate.the_key_challenging.guest_message2.error': "I think it happened in a different way. The way he tells it, I think someone had the key twice. Use the TAG ANSWER link above and let me know.",
            'translate.the_key_challenging.guest_message3.title': "That matches his story. So the Lady took it... Let’s hear what she has to say.",
            'translate.the_key_challenging.guest.timer.message1': 'The key changed hands 4 times, so you’ll have to enter 5 names.',
            'translate.the_key_challenging.guest.timer.message2': 'The lady took the bottle opener twice. The Boy is the only one who never had the key.',
            'translate.the_key_challenging.guest.hint.explanation': "You need to look closely at the video. Who took the bottle opener in the beginning and who had it in the end. You might not always see the opener, so sometimes you will have to deduct who might have taken it in the meantime.",
            'translate.the_key_challenging.guest.hint.hint1': "The key changed hands 4 times, so you’ll have to enter 5 names.",
            'translate.the_key_challenging.guest.hint.hint2': "The lady took the bottle opener twice. The Boy is the only one who never had the key.",
            'translate.the_key_challenging.guest.hint.solution': "Lady -  Caregiver - Baron - Peach - Lady. In the beginning of the scene you see the lady trying to open the bottle of the Boy. She messes up and leaves the key on the counter for the Caregiver to take it. You see the baron move towards the hands of the  Caregiver and then later you see Peach having the opener. Peach did not take it from the  Caregiver, so she would have taken it from the Baron. After Peach drops the opener, both Peach and the Lady drop to the floor, after which Peach shows empty hands.",

            'translate.the_interrogation_standard.timer.title': "5. The Interrogation - A Case in Paradise",
            'translate.the_interrogation_standard.timer.subtitle': "The challenge will start in:",
            'translate.the_interrogation_standard.message1.title': "So the Lady took the spare key. But why? Lets see if she is willing to tell us the truth. {{playerName}}, I will send you information about signs that indicate that she is lying.",
            'translate.the_interrogation_standard.message2.title': "If you focus on these two signs, {{partnerName}} will focus on the other signs. Let me know what she is lying about.",
            'translate.the_interrogation_standard.message2.error': "I believe that’s not the full story. I think there are five things she is lying about.",
            'translate.the_interrogation_standard.message3.title': "This would mean she is not the thief, but she definitely knows more. Let’s see what she remembers about this card game.",
            'translate.the_interrogation_standard.player.timer.message1': 'There are five things that she lies about.',
            'translate.the_interrogation_standard.player.timer.message2': 'She touches her hair twice before answering, one time she looks up, one time she repeats the question and one time she touches her shoulder.',
            'translate.the_interrogation_standard.player.hint.explanation': "You need to select the statements about which she is laying. To determine if she is lying you need to look out for the signs that each player have been given. Only if she uses one of these signs she is lying.",
            'translate.the_interrogation_standard.player.hint.hint1': "There are five things that she lies about.",
            'translate.the_interrogation_standard.player.hint.hint2': "She touches her hair twice before answering, one time she looks up, one time she repeats the question and one time she touches her shoulder.",
            'translate.the_interrogation_standard.player.hint.solution': "AEIKL. There are five lies in total.  The first is that she doesn’t know what Max is talking about (she shortly touches her hair before answering). The second lie is that she just wanted to have a peek because she was just curious (she touches her shoulder). She third lie is that she caused the blackout by accident (she repeats the question). The fourth lie is that she has no motive to steal it (she quickly looks up). The final lie is that  she doesn’t know any of the other guests (she touches her hair).",
            'translate.the_interrogation_standard.guest_message1.title': "So the Lady took the spare key. But why? Lets see if she is willing to tell us the truth. {{playerName}}, I will send you information about signs that indicate that she is lying.",
            'translate.the_interrogation_standard.guest_message2.title': "If you focus on these two signs, {{partnerName}} will focus on the other signs. Let me know what she is lying about.",
            'translate.the_interrogation_standard.guest_message2.error': "I believe that’s not the full story. I think there are five things she is lying about.",
            'translate.the_interrogation_standard.guest_message3.title': "This would mean she is not the thief, but she definitely knows more. Let’s see what she remembers about this card game.",
            'translate.the_interrogation_standard.guest.timer.message1': 'There are five things that she lies about.',
            'translate.the_interrogation_standard.guest.timer.message2': 'She touches her hair twice before answering, one time she looks up, one time she repeats the question and one time she touches her shoulder.',
            'translate.the_interrogation_standard.guest.hint.explanation': "You need to select the statements about which she is laying. To determine if she is lying you need to look out for the signs that each player have been given. Only if she uses one of these signs she is lying.",
            'translate.the_interrogation_standard.guest.hint.hint1': "There are five things that she lies about.",
            'translate.the_interrogation_standard.guest.hint.hint2': "She touches her hair twice before answering, one time she looks up, one time she repeats the question and one time she touches her shoulder.",
            'translate.the_interrogation_standard.guest.hint.solution': "AEIKL. There are five lies in total.  The first is that she doesn’t know what Max is talking about (she shortly touches her hair before answering). The second lie is that she just wanted to have a peek because she was just curious (she touches her shoulder). She third lie is that she caused the blackout by accident (she repeats the question). The fourth lie is that she has no motive to steal it (she quickly looks up). The final lie is that  she doesn’t know any of the other guests (she touches her hair).",

            'translate.the_interrogation_challenging.timer.title': "5. The Interrogation - A Case in Paradise",
            'translate.the_interrogation_challenging.timer.subtitle': "The challenge will start in:",
            'translate.the_interrogation_challenging.message1.title': "So the Lady took the spare key. But why? Lets see if she is willing to tell us the truth. {{playerName}}, I will send you information about signs that indicate that she is lying.",
            'translate.the_interrogation_challenging.message2.title': "If you focus on these two signs, {{partnerName}} will focus on the other signs. Let me know what she is lying about.",
            'translate.the_interrogation_challenging.message2.error': "I believe that’s not the full story. I think there are five things she is lying about.",
            'translate.the_interrogation_challenging.message3.title': "This would mean she is not the thief, but she definitely knows more. Let’s see what she remembers about this card game.",
            'translate.the_interrogation_challenging.player.timer.message1': 'There are five things that she lies about.',
            'translate.the_interrogation_challenging.player.timer.message2': 'She touches her hair twice before answering, one time she looks up, one time she repeats the question and one time she touches her shoulder.',
            'translate.the_interrogation_challenging.player.hint.explanation': "You need to select the statements about which she is laying. To determine if she is lying you need to look out for the signs that each player have been given. Only if she uses one of these signs she is lying.",
            'translate.the_interrogation_challenging.player.hint.hint1': "There are five things that she lies about.",
            'translate.the_interrogation_challenging.player.hint.hint2': "She touches her hair twice before answering, one time she looks up, one time she repeats the question and one time she touches her shoulder.",
            'translate.the_interrogation_challenging.player.hint.solution': "AEIKL. There are five lies in total.  The first is that she doesn’t know what Max is talking about (she shortly touches her hair before answering). The second lie is that she just wanted to have a peek because she was just curious (she touches her shoulder). She third lie is that she caused the blackout by accident (she repeats the question). The fourth lie is that she has no motive to steal it (she quickly looks up). The final lie is that  she doesn’t know any of the other guests (she touches her hair).",
            'translate.the_interrogation_challenging.guest_message1.title': "So the Lady took the spare key. But why? Lets see if she is willing to tell us the truth. {{playerName}}, I will send you information about signs that indicate that she is lying.",
            'translate.the_interrogation_challenging.guest_message2.title': "If you focus on these two signs, {{partnerName}} will focus on the other signs. Let me know what she is lying about.",
            'translate.the_interrogation_challenging.guest_message2.error': "I believe that’s not the full story. I think there are five things she is lying about.",
            'translate.the_interrogation_challenging.guest_message3.title': "This would mean she is not the thief, but she definitely knows more. Let’s see what she remembers about this card game.",
            'translate.the_interrogation_challenging.guest.timer.message1': 'There are five things that she lies about.',
            'translate.the_interrogation_challenging.guest.timer.message2': 'She touches her hair twice before answering, one time she looks up, one time she repeats the question and one time she touches her shoulder.',
            'translate.the_interrogation_challenging.guest.hint.explanation': "You need to select the statements about which she is laying. To determine if she is lying you need to look out for the signs that each player have been given. Only if she uses one of these signs she is lying.",
            'translate.the_interrogation_challenging.guest.hint.hint1': "There are five things that she lies about.",
            'translate.the_interrogation_challenging.guest.hint.hint2': "She touches her hair twice before answering, one time she looks up, one time she repeats the question and one time she touches her shoulder.",
            'translate.the_interrogation_challenging.guest.hint.solution': "AEIKL. There are five lies in total.  The first is that she doesn’t know what Max is talking about (she shortly touches her hair before answering). The second lie is that she just wanted to have a peek because she was just curious (she touches her shoulder). She third lie is that she caused the blackout by accident (she repeats the question). The fourth lie is that she has no motive to steal it (she quickly looks up). The final lie is that  she doesn’t know any of the other guests (she touches her hair).",

            'translate.the_card_game_standard.timer.title': "6. The Card Game - A Case in Paradise",
            'translate.the_card_game_standard.timer.subtitle': "The challenge will start in:",
            'translate.the_card_game_standard.message1.title': "So she says she was cheated. I think I recognize the game she is describing. I have sent you the rules below. Let’s hear what she can remember and see if we figure out how this game was played. If they were cheating we have to assume that the Boy and Peach knew each others cards, so feel free to communicate which cards you have.",
            'translate.the_card_game_standard.message2.title': "{{playerName}}, you play as the Boy and {{partnerName}}, you play as Peach. Let me know which cards were played in order for one person to win all 5 rounds. (FOR TESTERS, EACH ROUND THE CARDS ARE PLAYED AT THE SAME TIME BUT YOU NEED TO ENTER FIRST THE BOY AND THEN PEACH, FOR EXAMPLE AVBWCWDYEZ).",
            'translate.the_card_game_standard.message2.error': "That’s not right. They might have cheated with the cards, but the Lady said they did play by the rules. Only one person won all five rounds. Use the TAG ANSWER link above.",
            'translate.the_card_game_standard.message3.title': "So that's how Peach won all five rounds. Classic. Lets hear what she has to say about this.",
            'translate.the_card_game_standard.player.timer.message1': 'Only Peach has a Spades card, so she is the only one who can win all rounds. The Boy needs to find a way to lose some of his best cards so Peach can win each round. Think about which cards remain after you play each round.',
            'translate.the_card_game_standard.player.timer.message2': 'The second card that is played by the dealer is a Jack of Spades. The boy does not have any Spades, so he can play any card he wants. If he plays his Ace of Hearts, he can make sure he does not win with that card on a later turn.',
            'translate.the_card_game_standard.player.hint.explanation': "You need to play the cards in an order that one player will win all five rounds. If you are unfamiliar with card games in general, please check the card rules first.",
            'translate.the_card_game_standard.player.hint.hint1': "Only Peach has a Spades card, so she is the only one who can win all rounds. The Boy needs to find a way to lose some of his best cards so Peach can win each round. Think about which cards remain after you play each round.",
            'translate.the_card_game_standard.player.hint.hint2': "The second card that is played by the dealer is a Jack of Spades. The boy does not have any Spades, so he can play any card he wants. If he plays his Ace of Hearts, he can make sure he does not win with that card on a later turn.",
            'translate.the_card_game_standard.player.hint.solution': "CW, EZ, DX, BV, AY. The Lady plays a Jack of Spades, so the only person who can will all five rounds is Peach. Peach has a Queen of Spades and the Boy has no Spades. Peach needs to win the first round with a 10 of Hearts, the second with a Queen of Spades, the third with a King of Hearts, the fourth with a 7 of Hearts and the fifth with a Queen of Clubs. The first card the Boy can play is only the 8 of Hearts, to stay under the 10 of hearts of Peach. The boy has to play a Hearts, because Hearts is played by the dealer. The boy does not have any Spades, so on the second turn, he can now dump one of his best cards. He needs to play his Ace of Hearts, because if he keeps his Ace of Hearts he will win the round on the third of fourth card. The third round he now has only one Heart remaining, so he has to play Jack of Hearts. The fourth round he does not have any Hearts, so he can use this turn to dump his King of Clubs. The last turn he only has a Jack of Clubs which loses to Peaches Queen of Clubs.",
            'translate.the_card_game_standard.guest_message1.title': "So she says she was cheated. I think I recognize the game she is describing. I have sent you the rules below. Let’s hear what she can remember and see if we figure out how this game was played. If they were cheating we have to assume that the Boy and Peach knew each others cards, so feel free to communicate which cards you have.",
            'translate.the_card_game_standard.guest_message2.title': "{{playerName}}, you play as Peach and {{partnerName}}, you play as the Boy. Let me know which cards were played in order for one person to win all 5 rounds. (FOR TESTERS, EACH ROUND THE CARDS ARE PLAYED AT THE SAME TIME BUT YOU NEED TO ENTER FIRST THE BOY AND THEN PEACH, FOR EXAMPLE AVBWCWDYEZ).",
            'translate.the_card_game_standard.guest_message2.error': "That’s not right. They might have cheated with the cards, but the Lady said they did play by the rules. Only one person won all five rounds. Use the TAG ANSWER link above.",
            'translate.the_card_game_standard.guest_message3.title': "So that's how Peach won all five rounds. Classic. Lets hear what she has to say about this.",
            'translate.the_card_game_standard.guest.timer.message1': 'Only Peach has a Spades card, so she is the only one who can win all rounds. The Boy needs to find a way to lose some of his best cards so Peach can win each round. Think about which cards remain after you play each round.',
            'translate.the_card_game_standard.guest.timer.message2': 'The second card that is played by the dealer is a Jack of Spades. The boy does not have any Spades, so he can play any card he wants. If he plays his Ace of Hearts, he can make sure he does not win with that card on a later turn.',
            'translate.the_card_game_standard.guest.hint.explanation': "You need to play the cards in an order that one player will win all five rounds. If you are unfamiliar with card games in general, please check the card rules first.",
            'translate.the_card_game_standard.guest.hint.hint1': "Only Peach has a Spades card, so she is the only one who can win all rounds. The Boy needs to find a way to lose some of his best cards so Peach can win each round. Think about which cards remain after you play each round.",
            'translate.the_card_game_standard.guest.hint.hint2': "The second card that is played by the dealer is a Jack of Spades. The boy does not have any Spades, so he can play any card he wants. If he plays his Ace of Hearts, he can make sure he does not win with that card on a later turn.",
            'translate.the_card_game_standard.guest.hint.solution': "CW, EZ, DX, BV, AY. The Lady plays a Jack of Spades, so the only person who can will all five rounds is Peach. Peach has a Queen of Spades and the Boy has no Spades. Peach needs to win the first round with a 10 of Hearts, the second with a Queen of Spades, the third with a King of Hearts, the fourth with a 7 of Hearts and the fifth with a Queen of Clubs. The first card the Boy can play is only the 8 of Hearts, to stay under the 10 of hearts of Peach. The boy has to play a Hearts, because Hearts is played by the dealer. The boy does not have any Spades, so on the second turn, he can now dump one of his best cards. He needs to play his Ace of Hearts, because if he keeps his Ace of Hearts he will win the round on the third of fourth card. The third round he now has only one Heart remaining, so he has to play Jack of Hearts. The fourth round he does not have any Hearts, so he can use this turn to dump his King of Clubs. The last turn he only has a Jack of Clubs which loses to Peaches Queen of Clubs.",

            'translate.the_card_game_challenging.timer.title': "6. The Card Game - A Case in Paradise",
            'translate.the_card_game_challenging.timer.subtitle': "The challenge will start in:",
            'translate.the_card_game_challenging.message1.title': "So she says she was cheated. I think I recognize the game she is describing. I have sent you the rules below. Let’s hear what she can remember and see if we figure out how this game was played. If they were cheating we have to assume that the Boy and Peach knew each others cards, so feel free to communicate which cards you have.",
            'translate.the_card_game_challenging.message2.title': "{{playerName}}, you play as the Boy and {{partnerName}}, you play as Peach. Let me know which cards were played in order for one person to win all 5 rounds. (FOR TESTERS, EACH ROUND THE CARDS ARE PLAYED AT THE SAME TIME BUT YOU NEED TO ENTER FIRST THE BOY AND THEN PEACH, FOR EXAMPLE AVBWCWDYEZ).",
            'translate.the_card_game_challenging.message2.error': "That’s not right. They might have cheated with the cards, but the Lady said they did play by the rules. Only one person won all five rounds. Use the TAG ANSWER link above.",
            'translate.the_card_game_challenging.message3.title': "So that's how Peach won all five rounds. Classic. Lets hear what she has to say about this.",
            'translate.the_card_game_challenging.player.timer.message1': 'Only Peach has a Spades card, so she is the only one who can win all rounds. The Boy needs to find a way to lose some of his best cards so Peach can win each round. Think about which cards remain after you play each round.',
            'translate.the_card_game_challenging.player.timer.message2': 'The second card that is played by the dealer is a Jack of Spades. The boy does not have any Spades, so he can play any card he wants. If he plays his Ace of Hearts, he can make sure he does not win with that card on a later turn.',
            'translate.the_card_game_challenging.player.hint.explanation': "You need to play the cards in an order that one player will win all five rounds. If you are unfamiliar with card games in general, please check the card rules first.",
            'translate.the_card_game_challenging.player.hint.hint1': "Only Peach has a Spades card, so she is the only one who can win all rounds. The Boy needs to find a way to lose some of his best cards so Peach can win each round. Think about which cards remain after you play each round.",
            'translate.the_card_game_challenging.player.hint.hint2': "The second card that is played by the dealer is a Jack of Spades. The boy does not have any Spades, so he can play any card he wants. If he plays his Ace of Hearts, he can make sure he does not win with that card on a later turn.",
            'translate.the_card_game_challenging.player.hint.solution': "CW, EZ, DX, BV, AY. The Lady plays a Jack of Spades, so the only person who can will all five rounds is Peach. Peach has a Queen of Spades and the Boy has no Spades. Peach needs to win the first round with a 10 of Hearts, the second with a Queen of Spades, the third with a King of Hearts, the fourth with a 7 of Hearts and the fifth with a Queen of Clubs. The first card the Boy can play is only the 8 of Hearts, to stay under the 10 of hearts of Peach. The boy has to play a Hearts, because Hearts is played by the dealer. The boy does not have any Spades, so on the second turn, he can now dump one of his best cards. He needs to play his Ace of Hearts, because if he keeps his Ace of Hearts he will win the round on the third of fourth card. The third round he now has only one Heart remaining, so he has to play Jack of Hearts. The fourth round he does not have any Hearts, so he can use this turn to dump his King of Clubs. The last turn he only has a Jack of Clubs which loses to Peaches Queen of Clubs.",
            'translate.the_card_game_challenging.guest_message1.title': "So she says she was cheated. I think I recognize the game she is describing. I have sent you the rules below. Let’s hear what she can remember and see if we figure out how this game was played. If they were cheating we have to assume that the Boy and Peach knew each others cards, so feel free to communicate which cards you have.",
            'translate.the_card_game_challenging.guest_message2.title': "{{playerName}}, you play as Peach and {{partnerName}}, you play as the Boy. Let me know which cards were played in order for one person to win all 5 rounds. (FOR TESTERS, EACH ROUND THE CARDS ARE PLAYED AT THE SAME TIME BUT YOU NEED TO ENTER FIRST THE BOY AND THEN PEACH, FOR EXAMPLE AVBWCWDYEZ).",
            'translate.the_card_game_challenging.guest_message2.error': "That’s not right. They might have cheated with the cards, but the Lady said they did play by the rules. Only one person won all five rounds. Use the TAG ANSWER link above.",
            'translate.the_card_game_challenging.guest_message3.title': "So that's how Peach won all five rounds. Classic. Lets hear what she has to say about this.",
            'translate.the_card_game_challenging.guest.timer.message1': 'Only Peach has a Spades card, so she is the only one who can win all rounds. The Boy needs to find a way to lose some of his best cards so Peach can win each round. Think about which cards remain after you play each round.',
            'translate.the_card_game_challenging.guest.timer.message2': 'The second card that is played by the dealer is a Jack of Spades. The boy does not have any Spades, so he can play any card he wants. If he plays his Ace of Hearts, he can make sure he does not win with that card on a later turn.',
            'translate.the_card_game_challenging.guest.hint.explanation': "You need to play the cards in an order that one player will win all five rounds. If you are unfamiliar with card games in general, please check the card rules first.",
            'translate.the_card_game_challenging.guest.hint.hint1': "Only Peach has a Spades card, so she is the only one who can win all rounds. The Boy needs to find a way to lose some of his best cards so Peach can win each round. Think about which cards remain after you play each round.",
            'translate.the_card_game_challenging.guest.hint.hint2': "The second card that is played by the dealer is a Jack of Spades. The boy does not have any Spades, so he can play any card he wants. If he plays his Ace of Hearts, he can make sure he does not win with that card on a later turn.",
            'translate.the_card_game_challenging.guest.hint.solution': "CW, EZ, DX, BV, AY. The Lady plays a Jack of Spades, so the only person who can will all five rounds is Peach. Peach has a Queen of Spades and the Boy has no Spades. Peach needs to win the first round with a 10 of Hearts, the second with a Queen of Spades, the third with a King of Hearts, the fourth with a 7 of Hearts and the fifth with a Queen of Clubs. The first card the Boy can play is only the 8 of Hearts, to stay under the 10 of hearts of Peach. The boy has to play a Hearts, because Hearts is played by the dealer. The boy does not have any Spades, so on the second turn, he can now dump one of his best cards. He needs to play his Ace of Hearts, because if he keeps his Ace of Hearts he will win the round on the third of fourth card. The third round he now has only one Heart remaining, so he has to play Jack of Hearts. The fourth round he does not have any Hearts, so he can use this turn to dump his King of Clubs. The last turn he only has a Jack of Clubs which loses to Peaches Queen of Clubs.",

            'translate.the_missing_items_standard.timer.title': "7. The Missing Items - A Case in Paradise",
            'translate.the_missing_items_standard.timer.subtitle': "The challenge will start in:",
            'translate.the_missing_items_standard.message1.title': "So this Boy steals items from other guests to give them to Peach? Let’s check out his room and see what he might have stolen. I have one note from the owner with notes about the missing items and another one from Peach with her favorite colors.",
            'translate.the_missing_items_standard.message2.title': "Let's find the items that match both the description of the owner and the favorite colours of Peach. {{playerName}}, I have sent you the list of her favorite colours. {{partnerName}} I sent you a list from the owner. (Note for testers, enter the solution in the order of the list from the owner).",
            'translate.the_missing_items_standard.message2.error': "I looked around, but there are some items that do not match. Can you check it again? Use the TAG ANSWER link above. ",
            'translate.the_missing_items_standard.message3.title': "At least one mystery is solved. Strange way to impress someone...",
            'translate.the_missing_items_standard.player.timer.message1': 'There is a red dress with dots lying on the sofa.',
            'translate.the_missing_items_standard.player.timer.message2': 'TThere is a white lipstick with TP on it lying on the sofa.',
            'translate.the_missing_items_standard.player.hint.explanation': "You need to find which items match both descriptions from the owner and from peach. For example if there is something yellow with dots, the first letter of the code would be F (because 1. is something with dots). You only have to use each color and each description once.",
            'translate.the_missing_items_standard.player.hint.hint1': "There is a red dress with dots lying on the sofa.",
            'translate.the_missing_items_standard.player.hint.hint2': "There is a white lipstick with TP on it lying on the sofa.",
            'translate.the_missing_items_standard.player.hint.solution': "The answer is BEDCFA. A red dress with dots on it, white lipstick with TP on it, black lipstick with UNG on it, a silver purse with a V on it, and yellow lipstick with a rectangular shape and a golden bracelet with a pearl on it.",
            'translate.the_missing_items_standard.guest_message1.title': "So this Boy steals items from other guests to give them to Peach? Let’s check out his room and see what he might have stolen. I have one note from the owner with notes about the missing items and another one from Peach with her favorite colors.",
            'translate.the_missing_items_standard.guest_message2.title': "Let's find the items that match both the description of the owner and the favorite colours of Peach. {{playerName}}, I have sent you a list from the owner with the description of the items. {{partnerName}} I sent you a list with the favorite colors from Peach. (Note for testers, enter the solution in the order on the list of the owner).",
            'translate.the_missing_items_standard.guest_message2.error': "I looked around, but there are some items that do not match. Can you check it again? Use the TAG ANSWER link above. ",
            'translate.the_missing_items_standard.guest_message3.title': "At least one mystery is solved. Strange way to impress someone...",
            'translate.the_missing_items_standard.guest.timer.message1': 'There is a red dress with dots lying on the sofa.',
            'translate.the_missing_items_standard.guest.timer.message2': 'TThere is a white lipstick with TP on it lying on the sofa.',
            'translate.the_missing_items_standard.guest.hint.explanation': "You need to find which items match both descriptions from the owner and from peach. For example if there is something yellow with dots, the first letter of the code would be F (because 1. is something with dots). You only have to use each color and each description once.",
            'translate.the_missing_items_standard.guest.hint.hint1': "There is a red dress with dots lying on the sofa.",
            'translate.the_missing_items_standard.guest.hint.hint2': "There is a white lipstick with TP on it lying on the sofa.",
            'translate.the_missing_items_standard.hint.solution': "The answer is BEDCFA. A red dress with dots on it, white lipstick with TP on it, black lipstick with UNG on it, a silver purse with a V on it, and yellow lipstick with a rectangular shape and a golden bracelet with a pearl on it.",

            'translate.the_missing_items_challenging.timer.title': "7. The Missing Items - A Case in Paradise",
            'translate.the_missing_items_challenging.timer.subtitle': "The challenge will start in:",
            'translate.the_missing_items_challenging.message1.title': "So this Boy steals items from other guests to give them to Peach? Let’s check out his room and see what he might have stolen. I have one note from the owner with notes about the missing items and another one from Peach with her favorite colors.",
            'translate.the_missing_items_challenging.message2.title': "Let's find the items that match both the description of the owner and the favorite colours of Peach. {{playerName}}, I have sent you the list of her favorite colours. {{partnerName}} I sent you a list from the owner. (Note for testers, enter the solution in the order of the list from the owner).",
            'translate.the_missing_items_challenging.message2.error': "I looked around, but there are some items that do not match. Can you check it again? Use the TAG ANSWER link above. ",
            'translate.the_missing_items_challenging.message3.title': "At least one mystery is solved. Strange way to impress someone...",
            'translate.the_missing_items_challenging.player.timer.message1': 'There is a red dress with dots lying on the sofa.',
            'translate.the_missing_items_challenging.player.timer.message2': 'TThere is a white lipstick with TP on it lying on the sofa.',
            'translate.the_missing_items_challenging.player.hint.explanation': "You need to find which items match both descriptions from the owner and from peach. For example if there is something yellow with dots, the first letter of the code would be F (because 1. is something with dots). You only have to use each color and each description once.",
            'translate.the_missing_items_challenging.player.hint.hint1': "There is a red dress with dots lying on the sofa.",
            'translate.the_missing_items_challenging.player.hint.hint2': "There is a white lipstick with TP on it lying on the sofa.",
            'translate.the_missing_items_challenging.player.hint.solution': "The answer is BEDCFA. A red dress with dots on it, white lipstick with TP on it, black lipstick with UNG on it, a silver purse with a V on it, and yellow lipstick with a rectangular shape and a golden bracelet with a pearl on it.",
            'translate.the_missing_items_challenging.guest_message1.title': "So this Boy steals items from other guests to give them to Peach? Let’s check out his room and see what he might have stolen. I have one note from the owner with notes about the missing items and another one from Peach with her favorite colors.",
            'translate.the_missing_items_challenging.guest_message2.title': "Let's find the items that match both the description of the owner and the favorite colours of Peach. {{playerName}}, I have sent you a list from the owner with the description of the items. {{partnerName}} I sent you a list with the favorite colors from Peach. (Note for testers, enter the solution in the order on the list of the owner).",
            'translate.the_missing_items_challenging.guest_message2.error': "I looked around, but there are some items that do not match. Can you check it again? Use the TAG ANSWER link above. ",
            'translate.the_missing_items_challenging.guest_message3.title': "At least one mystery is solved. Strange way to impress someone...",
            'translate.the_missing_items_challenging.guest.timer.message1': 'There is a red dress with dots lying on the sofa.',
            'translate.the_missing_items_challenging.guest.timer.message2': 'TThere is a white lipstick with TP on it lying on the sofa.',
            'translate.the_missing_items_challenging.guest.hint.explanation': "You need to find which items match both descriptions from the owner and from peach. For example if there is something yellow with dots, the first letter of the code would be F (because 1. is something with dots). You only have to use each color and each description once.",
            'translate.the_missing_items_challenging.guest.hint.hint1': "There is a red dress with dots lying on the sofa.",
            'translate.the_missing_items_challenging.guest.hint.hint2': "There is a white lipstick with TP on it lying on the sofa.",
            'translate.the_missing_items_challenging.hint.solution': "The answer is BEDCFA. A red dress with dots on it, white lipstick with TP on it, black lipstick with UNG on it, a silver purse with a V on it, and yellow lipstick with a rectangular shape and a golden bracelet with a pearl on it.",

            'translate.the_traces_standard.timer.title': "8. Traces - A Case in Paradise",
            'translate.the_traces_standard.timer.subtitle': "The challenge will start in:",
            'translate.the_traces_standard.message1.title': "I just lost him. Pretty fast runner… ",
            'translate.the_traces_standard.message2.title': "Let’s see if we can figure out where he went. Focus on the traces that he left and let me know the locations in the right order. I know he started near rooms 161-169.",
            'translate.the_traces_standard.message2.error': "I have walked that route, but it doesn’t completely matches the traces he left. I think there were four things that happened during his flight.",
            'translate.the_traces_standard.message3.title': "That means he ended up with on shoe in the Attic. Let’s see if we can find him there",
            'translate.the_traces_standard.player.timer.message1': 'There are four things that happened during his flight. After each of these, there is a change in the  traces he leaves, which can help you determine the order of how these things happened.',
            'translate.the_traces_standard.player.timer.message2': 'You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26. During his flight four things happened (not in this order); he loses his jacket, knocks over a vase, loses one shoe and steps into a bucket of water. You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26. ',
            'translate.the_traces_standard.player.hint.explanation': "You need to figure out in which order the Boy passed through the floors. On some floors something happened that changed his traces. First try to figure out what happened during his flight. Then focus on how the traces change after each thing that happen to determine the right order.",
            'translate.the_traces_standard.player.hint.hint1': "There are four things that happened during his flight. After each of these, there is a change in the  traces he leaves, which can help you determine the order of how these things happened.",
            'translate.the_traces_standard.player.hint.hint2': "You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26. During his flight four things happened (not in this order); he loses his jacket, knocks over a vase, loses one shoe and steps into a bucket of water. You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26.",
            'translate.the_traces_standard.player.hint.solution': "(161-169) (261-269). (151-159). Attic B. (251-259). (241-249). (141-149). Attic A. He starts in room 162 (there are no traces there). You see the first traces on near room 262. After he knocks over a vase, he leaves a trace of dried flowers. You see only these traces near room 151. On attic B you see a trace of dried flowers, a bucket that has been kicked over and then a trace of both dried flowers and wet footsteps. Near room 252 you see dried flowers and wet footsteps until he loses his jacket, after which only the wet footprints remain. Near room 243 you only see wet footprints. Near room 142 you see wet footprints and one shoe, after which the traces show only one foot instead of two. On attic A  you only see one trace of one foot, which leaves this as the place that he is hiding.",
            'translate.the_traces_standard.guest_message1.title': "I just lost him. Pretty fast runner… ",
            'translate.the_traces_standard.guest_message2.title': "Let’s see if we can figure out where he went. Focus on the traces that he left and let me know the locations in the right order. I know he started near rooms 161-169.",
            'translate.the_traces_standard.guest_message2.error': "I have walked that route, but it doesn’t completely matches the traces he left. I think there were four things that happened during his flight.",
            'translate.the_traces_standard.guest_message3.title': "That means he ended up with on shoe in the Attic. Let’s see if we can find him there",
            'translate.the_traces_standard.guest.timer.message1': 'There are four things that happened during his flight. After each of these, there is a change in the  traces he leaves, which can help you determine the order of how these things happened.',
            'translate.the_traces_standard.guest.timer.message2': 'You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26. During his flight four things happened (not in this order); he loses his jacket, knocks over a vase, loses one shoe and steps into a bucket of water. You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26. ',
            'translate.the_traces_standard.guest.hint.explanation': "You need to figure out in which order the Boy passed through the floors. On some floors something happened that changed his traces. First try to figure out what happened during his flight. Then focus on how the traces change after each thing that happen to determine the right order.",
            'translate.the_traces_standard.guest.hint.hint1': "There are four things that happened during his flight. After each of these, there is a change in the  traces he leaves, which can help you determine the order of how these things happened.",
            'translate.the_traces_standard.guest.hint.hint2': "You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26. During his flight four things happened (not in this order); he loses his jacket, knocks over a vase, loses one shoe and steps into a bucket of water. You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26.",
            'translate.the_traces_standard.guest.hint.solution': "(161-169) (261-269). (151-159). Attic B. (251-259). (241-249). (141-149). Attic A. He starts in room 162 (there are no traces there). You see the first traces on near room 262. After he knocks over a vase, he leaves a trace of dried flowers. You see only these traces near room 151. On attic B you see a trace of dried flowers, a bucket that has been kicked over and then a trace of both dried flowers and wet footsteps. Near room 252 you see dried flowers and wet footsteps until he loses his jacket, after which only the wet footprints remain. Near room 243 you only see wet footprints. Near room 142 you see wet footprints and one shoe, after which the traces show only one foot instead of two. On attic A  you only see one trace of one foot, which leaves this as the place that he is hiding.",

            'translate.the_traces_challenging.timer.title': "8. Traces - A Case in Paradise",
            'translate.the_traces_challenging.timer.subtitle': "The challenge will start in:",
            'translate.the_traces_challenging.message1.title': "I just lost him. Pretty fast runner… ",
            'translate.the_traces_challenging.message2.title': "Let’s see if we can figure out where he went. Focus on the traces that he left and let me know the locations in the right order. I know he started near rooms 161-169.",
            'translate.the_traces_challenging.message2.error': "I have walked that route, but it doesn’t completely matches the traces he left. I think there were four things that happened during his flight.",
            'translate.the_traces_challenging.message3.title': "That means he ended up with on shoe in the Attic. Let’s see if we can find him there",
            'translate.the_traces_challenging.player.timer.message1': 'There are four things that happened during his flight. After each of these, there is a change in the  traces he leaves, which can help you determine the order of how these things happened.',
            'translate.the_traces_challenging.player.timer.message2': 'You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26. During his flight four things happened (not in this order); he loses his jacket, knocks over a vase, loses one shoe and steps into a bucket of water. You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26. ',
            'translate.the_traces_challenging.player.hint.explanation': "You need to figure out in which order the Boy passed through the floors. On some floors something happened that changed his traces. First try to figure out what happened during his flight. Then focus on how the traces change after each thing that happen to determine the right order.",
            'translate.the_traces_challenging.player.hint.hint1': "There are four things that happened during his flight. After each of these, there is a change in the  traces he leaves, which can help you determine the order of how these things happened.",
            'translate.the_traces_challenging.player.hint.hint2': "You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26. During his flight four things happened (not in this order); he loses his jacket, knocks over a vase, loses one shoe and steps into a bucket of water. You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26.",
            'translate.the_traces_challenging.player.hint.solution': "(161-169) (261-269). (151-159). Attic B. (251-259). (241-249). (141-149). Attic A. He starts in room 162 (there are no traces there). You see the first traces on near room 262. After he knocks over a vase, he leaves a trace of dried flowers. You see only these traces near room 151. On attic B you see a trace of dried flowers, a bucket that has been kicked over and then a trace of both dried flowers and wet footsteps. Near room 252 you see dried flowers and wet footsteps until he loses his jacket, after which only the wet footprints remain. Near room 243 you only see wet footprints. Near room 142 you see wet footprints and one shoe, after which the traces show only one foot instead of two. On attic A  you only see one trace of one foot, which leaves this as the place that he is hiding.",
            'translate.the_traces_challenging.guest_message1.title': "I just lost him. Pretty fast runner… ",
            'translate.the_traces_challenging.guest_message2.title': "Let’s see if we can figure out where he went. Focus on the traces that he left and let me know the locations in the right order. I know he started near rooms 161-169.",
            'translate.the_traces_challenging.guest_message2.error': "I have walked that route, but it doesn’t completely matches the traces he left. I think there were four things that happened during his flight.",
            'translate.the_traces_challenging.guest_message3.title': "That means he ended up with on shoe in the Attic. Let’s see if we can find him there",
            'translate.the_traces_challenging.guest.timer.message1': 'There are four things that happened during his flight. After each of these, there is a change in the  traces he leaves, which can help you determine the order of how these things happened.',
            'translate.the_traces_challenging.guest.timer.message2': 'You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26. During his flight four things happened (not in this order); he loses his jacket, knocks over a vase, loses one shoe and steps into a bucket of water. You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26. ',
            'translate.the_traces_challenging.guest.hint.explanation': "You need to figure out in which order the Boy passed through the floors. On some floors something happened that changed his traces. First try to figure out what happened during his flight. Then focus on how the traces change after each thing that happen to determine the right order.",
            'translate.the_traces_challenging.guest.hint.hint1': "There are four things that happened during his flight. After each of these, there is a change in the  traces he leaves, which can help you determine the order of how these things happened.",
            'translate.the_traces_challenging.guest.hint.hint2': "You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26. During his flight four things happened (not in this order); he loses his jacket, knocks over a vase, loses one shoe and steps into a bucket of water. You will see the first traces of dried flowers appear right after he knocks over a vase on floor 26.",
            'translate.the_traces_challenging.guest.hint.solution': "(161-169) (261-269). (151-159). Attic B. (251-259). (241-249). (141-149). Attic A. He starts in room 162 (there are no traces there). You see the first traces on near room 262. After he knocks over a vase, he leaves a trace of dried flowers. You see only these traces near room 151. On attic B you see a trace of dried flowers, a bucket that has been kicked over and then a trace of both dried flowers and wet footsteps. Near room 252 you see dried flowers and wet footsteps until he loses his jacket, after which only the wet footprints remain. Near room 243 you only see wet footprints. Near room 142 you see wet footprints and one shoe, after which the traces show only one foot instead of two. On attic A  you only see one trace of one foot, which leaves this as the place that he is hiding.",

            'translate.the_security_tapes_standard.timer.title': "9. The Tapes- A Case in Paradise",
            'translate.the_security_tapes_standard.timer.subtitle': "The challenge will start in:",
            'translate.the_security_tapes_standard.message1.title': "Lets see what’s on those tapes. We need to figure out where everybody was before the blackout started.",
            'translate.the_security_tapes_standard.message2.title': "Let me know which rooms they ended up in, in the same order that they got there. (FOR TESTERS note that if you use a room multiple times in the solution, use the (1st) version first and the (2nd) version second.)",
            'translate.the_security_tapes_standard.message2.error': "That doesn’t match what I’m seeing here on the tapes. Can you check again?  Use the TAG ANSWER link above.",
            'translate.the_security_tapes_standard.message3.title': "Interesting. Now let’s see if we can find out who did it.",
            'translate.the_security_tapes_standard.player.timer.message1': 'The caregiver first goes to the bar and stays there. Focus on seeing who goes in the bar, who leaves the bar and in what order.',
            'translate.the_security_tapes_standard.player.timer.message2': 'The order in which they end up in the room before the blackout is Caregiver, Baron, Peach, Owner, Lady and Boy.',
            'translate.the_security_tapes_standard.player.hint.explanation': "You need to figure out in which room they started, where they ended up right before the blackout and in which order they end up there. Focus on one person at a time to find out what happened.",
            'translate.the_security_tapes_standard.player.hint.hint1': "The caregiver first goes to the bar and stays there. Focus on seeing who goes in the bar, who leaves the bar and in what order.",
            'translate.the_security_tapes_standard.player.hint.hint2': "The order in which they end up in the room before the blackout is Caregiver, Baron, Peach, Owner, Lady and Boy.",
            'translate.the_security_tapes_standard.player.hint.solution': "1. Bar (Caretaker). 2 Bar (Baron) 3. 248 (Peach). 4. Office (Owner). 5. Hallway (Lady). 6. Lobby (Boy). You see the caretaker appear first and move to the bar. At that point the baron is still standing there. You see the owner move the baron to the bar. After that you first see Peach over from the bar to upstairs (but she never appears on camera near room 274, so she must be in room 248. When the lady moves down you see the owner, who goes in the office before she enters the lobby. Finally you see the Boy who follows the Lady.",
            'translate.the_security_tapes_standard.guest_message1.title': "Lets see what’s on those tapes. We need to figure out where everybody was before the blackout started.",
            'translate.the_security_tapes_standard.guest_message2.title': "Let me know which rooms they ended up in, in the same order that they got there. (FOR TESTERS note that if you use a room multiple times in the solution, use the (1st) version first and the (2nd) version second.) ",
            'translate.the_security_tapes_standard.guest_message2.error': "That doesn’t match what I’m seeing here on the tapes. Can you check again?  Use the TAG ANSWER link above.",
            'translate.the_security_tapes_standard.guest_message3.title': "Interesting. Now let’s see if we can find out who did it.",
            'translate.the_security_tapes_standard.guest.timer.message1': 'The caregiver first goes to the bar and stays there. Focus on seeing who goes in the bar, who leaves the bar and in what order.',
            'translate.the_security_tapes_standard.guest.timer.message2': 'The order in which they end up in the room before the blackout is Caregiver, Baron, Peach, Owner, Lady and Boy.',
            'translate.the_security_tapes_standard.guest.hint.explanation': "You need to figure out in which room they started, where they ended up right before the blackout and in which order they end up there. Focus on one person at a time to find out what happened.",
            'translate.the_security_tapes_standard.guest.hint.hint1': "The caregiver first goes to the bar and stays there. Focus on seeing who goes in the bar, who leaves the bar and in what order.",
            'translate.the_security_tapes_standard.guest.hint.hint2': "The order in which they end up in the room before the blackout is Caregiver, Baron, Peach, Owner, Lady and Boy.",
            'translate.the_security_tapes_standard.guest.hint.solution': "1. Bar (Caretaker). 2 Bar (Baron) 3. 248 (Peach). 4. Office (Owner). 5. Hallway (Lady). 6. Lobby (Boy). You see the caretaker appear first and move to the bar. At that point the baron is still standing there. You see the owner move the baron to the bar. After that you first see Peach over from the bar to upstairs (but she never appears on camera near room 274, so she must be in room 248. When the lady moves down you see the owner, who goes in the office before she enters the lobby. Finally you see the Boy who follows the Lady.",

            'translate.the_security_tapes_challenging.timer.title': "9. The Tapes- A Case in Paradise",
            'translate.the_security_tapes_challenging.timer.subtitle': "The challenge will start in:",
            'translate.the_security_tapes_challenging.message1.title': "Lets see what’s on those tapes. We need to figure out where everybody was before the blackout started.",
            'translate.the_security_tapes_challenging.message2.title': "Let me know which rooms they ended up in, in the same order that they got there. (FOR TESTERS note that if you use a room multiple times in your solution, use the (1st) version first and the (2nd) version second.) ",
            'translate.the_security_tapes_challenging.message2.error': "That doesn’t match what I’m seeing here on the tapes. Can you check again?  Use the TAG ANSWER link above.",
            'translate.the_security_tapes_challenging.message3.title': "Interesting. Now let’s see if we can find out who did it.",
            'translate.the_security_tapes_challenging.player.timer.message1': 'The caregiver first goes to the bar and stays there. Focus on seeing who goes in the bar, who leaves the bar and in what order.',
            'translate.the_security_tapes_challenging.player.timer.message2': 'The order in which they end up in the room before the blackout is Caregiver, Baron, Peach, Owner, Lady and Boy.',
            'translate.the_security_tapes_challenging.player.hint.explanation': "You need to figure out in which room they started, where they ended up right before the blackout and in which order they end up there. Focus on one person at a time to find out what happened.",
            'translate.the_security_tapes_challenging.player.hint.hint1': "The caregiver first goes to the bar and stays there. Focus on seeing who goes in the bar, who leaves the bar and in what order.",
            'translate.the_security_tapes_challenging.player.hint.hint2': "The order in which they end up in the room before the blackout is Caregiver, Baron, Peach, Owner, Lady and Boy.",
            'translate.the_security_tapes_challenging.player.hint.solution': "1. Bar (Caretaker). 2 Bar (Baron) 3. 248 (Peach). 4. Office (Owner). 5. Hallway (Lady). 6. Lobby (Boy). You see the caretaker appear first and move to the bar. At that point the baron is still standing there. You see the owner move the baron to the bar. After that you first see Peach over from the bar to upstairs (but she never appears on camera near room 274, so she must be in room 248. When the lady moves down you see the owner, who goes in the office before she enters the lobby. Finally you see the Boy who follows the Lady.",
            'translate.the_security_tapes_challenging.guest_message1.title': "Lets see what’s on those tapes. We need to figure out where everybody was before the blackout started.",
            'translate.the_security_tapes_challenging.guest_message2.title': "Let me know which rooms they ended up in, in the same order that they got there.(FOR TESTERS note that if you use a room multiple times in your solution, use the (1st) version first and the (2nd) version second.) ",
            'translate.the_security_tapes_challenging.guest_message2.error': "That doesn’t match what I’m seeing here on the tapes. Can you check again?  Use the TAG ANSWER link above.",
            'translate.the_security_tapes_challenging.guest_message3.title': "Interesting. Now let’s see if we can find out who did it.",
            'translate.the_security_tapes_challenging.guest.timer.message1': 'The caregiver first goes to the bar and stays there. Focus on seeing who goes in the bar, who leaves the bar and in what order.',
            'translate.the_security_tapes_challenging.guest.timer.message2': 'The order in which they end up in the room before the blackout is Caregiver, Baron, Peach, Owner, Lady and Boy.',
            'translate.the_security_tapes_challenging.guest.hint.explanation': "You need to figure out in which room they started, where they ended up right before the blackout and in which order they end up there. Focus on one person at a time to find out what happened.",
            'translate.the_security_tapes_challenging.guest.hint.hint1': "The caregiver first goes to the bar and stays there. Focus on seeing who goes in the bar, who leaves the bar and in what order.",
            'translate.the_security_tapes_challenging.guest.hint.hint2': "The order in which they end up in the room before the blackout is Caregiver, Baron, Peach, Owner, Lady and Boy.",
            'translate.the_security_tapes_challenging.guest.hint.solution': "1. Bar (Caretaker). 2 Bar (Baron) 3. 248 (Peach). 4. Office (Owner). 5. Hallway (Lady). 6. Lobby (Boy). You see the caretaker appear first and move to the bar. At that point the baron is still standing there. You see the owner move the baron to the bar. After that you first see Peach over from the bar to upstairs (but she never appears on camera near room 274, so she must be in room 248. When the lady moves down you see the owner, who goes in the office before she enters the lobby. Finally you see the Boy who follows the Lady.",

            'translate.whodunnit_standard.timer.title': "10. Whodunnit - A Case in Paradise",
            'translate.whodunnit_standard.timer.subtitle': "The challenge will start in:",
            'translate.whodunnit_standard.message1.title': "We have all the facts. It is time to put it all together.",
            'translate.whodunnit_standard.message2.title': "Let me know what you think happened. (FOR TESTERS the first time you use a word, please use the (1st) version, the second time the (2nd) version and so on).",
            'translate.whodunnit_standard.message2.error': "Parts of that sound right, but I believe it is not the full story. Did you notice which things changed after the blackout?",
            'translate.whodunnit_standard.message3.title': "Thank you. Well spotted. I believe it is time to confront them. Watch 11. Confrontation, before finishing the game.",
            'translate.whodunnit_standard.player.timer.message1': 'There are two main things that changed after the blackout. One is clearly visible, the other has to do with one of the suspects.',
            'translate.whodunnit_standard.player.timer.message2': 'There is a trail that goes from the front door to the bar. Watch the first 8-12 seconds of the video closely and compare this with the video of 1:33 to 1:38. What difference do you see? And what is the reason for this?',
            'translate.whodunnit_standard.player.hint.explanation': "Your goal is to deduce the route that Linda is taking. Focus first on Jennifer and Justin (the researcher). Where are they and do they hear something in the room or rooms next to them?",
            'translate.whodunnit_standard.player.hint.hint1': "There are two main things that changed after the blackout. One is clearly visible, the other has to do with one of the suspects.",
            'translate.whodunnit_standard.player.hint.hint2': "There is a trail that goes from the front door to the bar. Watch the first 8-12 seconds of the video closely and compare this with the video of 1:33 to 1:38. What difference do you see? And what is the reason for this?",
            'translate.whodunnit_standard.player.hint.solution': "(1) The Caretaker and (2) the Lady planned to steal the totem.  But the real thief knew of their plans. The thief planned to use (3) chloroform to take out (4) the Lady, but when the blackout started the Boy (5) was in his way. When the thief approached the totem room, the Lady (6) got scared and hid in a closet. The thief pushed over a bowl, the Lady (6) screamed, alerting the Owner (7). The thief had no choice but to escape by (8) the window. When the thief re-entered, they left a trail which led to the (9 bar. There, the thief hid his (10) shoes because on the video the Baron shows is white socks while he had shoes on before the blackout. So we know the thief is the baron (11).",
            'translate.whodunnit_standard.guest_message1.title': "We have all the facts. It is time to put it all together.",
            'translate.whodunnit_standard.guest_message2.title': "Let me know what you think happened. (FOR TESTERS the first time you use a word, please use the (1st) version, the second time the (2nd) version and so on). ",
            'translate.whodunnit_standard.guest_message2.error': "Parts of that sound right, but I believe it is not the full story. Did you notice which things changed after the blackout?",
            'translate.whodunnit_standard.guest_message3.title': "Thank you. Well spotted. I believe it is time to confront them. Watch 11. Confrontation, before finishing the game.",
            'translate.whodunnit_standard.guest.timer.message1': 'There are two main things that changed after the blackout. One is clearly visible, the other has to do with one of the suspects.',
            'translate.whodunnit_standard.guest.timer.message2': 'There is a trail that goes from the front door to the bar. Watch the first 8-12 seconds of the video closely and compare this with the video of 1:33 to 1:38. What difference do you see? And what is the reason for this?',
            'translate.whodunnit_standard.guest.hint.explanation': "Your goal is to deduce the route that Linda is taking. Focus first on Jennifer and Justin (the researcher). Where are they and do they hear something in the room or rooms next to them?",
            'translate.whodunnit_standard.guest.hint.hint1': "There are two main things that changed after the blackout. One is clearly visible, the other has to do with one of the suspects.",
            'translate.whodunnit_standard.guest.hint.hint2': "There is a trail that goes from the front door to the bar. Watch the first 8-12 seconds of the video closely and compare this with the video of 1:33 to 1:38. What difference do you see? And what is the reason for this?",
            'translate.whodunnit_standard.guest.hint.solution': "(1) The Caretaker and (2) the Lady planned to steal the totem.  But the real thief knew of their plans. The thief planned to use (3) chloroform to take out (4) the Lady, but when the blackout started the Boy (5) was in his way. When the thief approached the totem room, the Lady (6) got scared and hid in a closet. The thief pushed over a bowl, the Lady (6) screamed, alerting the Owner (7). The thief had no choice but to escape by (8) the window. When the thief re-entered, they left a trail which led to the (9 bar. There, the thief hid his (10) shoes because on the video the Baron shows is white socks while he had shoes on before the blackout. So we know the thief is the baron (11).",

            'translate.whodunnit_challenging.timer.title': "10. Whodunnit - A Case in Paradise",
            'translate.whodunnit_challenging.timer.subtitle': "The challenge will start in:",
            'translate.whodunnit_challenging.message1.title': "We have all the facts. It is time to put it all together.",
            'translate.whodunnit_challenging.message2.title': "Let me know what you think happened. (FOR TESTERS the first time you use a word, please use the (1st) version, the second time the (2nd) version and so on).",
            'translate.whodunnit_challenging.message2.error': "Parts of that sound right, but I believe it is not the full story. Did you notice which things changed after the blackout?",
            'translate.whodunnit_challenging.message3.title': "Thank you. Well spotted. I believe it is time to confront them. Watch 11. Confrontation, before finishing the game.",
            'translate.whodunnit_challenging.player.timer.message1': 'There are two main things that changed after the blackout. One is clearly visible, the other has to do with one of the suspects.',
            'translate.whodunnit_challenging.player.timer.message2': 'There is a trail that goes from the front door to the bar. Watch the first 8-12 seconds of the video closely and compare this with the video of 1:33 to 1:38. What difference do you see? And what is the reason for this?',
            'translate.whodunnit_challenging.player.hint.explanation': "Your goal is to deduce the route that Linda is taking. Focus first on Jennifer and Justin (the researcher). Where are they and do they hear something in the room or rooms next to them?",
            'translate.whodunnit_challenging.player.hint.hint1': "There are two main things that changed after the blackout. One is clearly visible, the other has to do with one of the suspects.",
            'translate.whodunnit_challenging.player.hint.hint2': "There is a trail that goes from the front door to the bar. Watch the first 8-12 seconds of the video closely and compare this with the video of 1:33 to 1:38. What difference do you see? And what is the reason for this?",
            'translate.whodunnit_challenging.player.hint.solution': "(1) The Caretaker and (2) the Lady planned to steal the totem.  But the real thief knew of their plans. The thief planned to use (3) chloroform to take out (4) the Lady, but when the blackout started the Boy (5) was in his way. When the thief approached the totem room, the Lady (6) got scared and hid in a closet. The thief pushed over a bowl, the Lady (6) screamed, alerting the Owner (7). The thief had no choice but to escape by (8) the window. When the thief re-entered, they left a trail which led to the (9 bar. There, the thief hid his (10) shoes because on the video the Baron shows is white socks while he had shoes on before the blackout. So we know the thief is the baron (11).",
            'translate.whodunnit_challenging.guest_message1.title': "We have all the facts. It is time to put it all together.",
            'translate.whodunnit_challenging.guest_message2.title': "Let me know what you think happened. (FOR TESTERS the first time you use a word, please use the (1st) version, the second time the (2nd) version and so on).",
            'translate.whodunnit_challenging.guest_message2.error': "Parts of that sound right, but I believe it is not the full story. Did you notice which things changed after the blackout?",
            'translate.whodunnit_challenging.guest_message3.title': "Thank you. Well spotted. I believe it is time to confront them. Watch 11. Confrontation, before finishing the game.",
            'translate.whodunnit_challenging.guest.timer.message1': 'There are two main things that changed after the blackout. One is clearly visible, the other has to do with one of the suspects.',
            'translate.whodunnit_challenging.guest.timer.message2': 'There is a trail that goes from the front door to the bar. Watch the first 8-12 seconds of the video closely and compare this with the video of 1:33 to 1:38. What difference do you see? And what is the reason for this?',
            'translate.whodunnit_challenging.guest.hint.explanation': "Your goal is to deduce the route that Linda is taking. Focus first on Jennifer and Justin (the researcher). Where are they and do they hear something in the room or rooms next to them?",
            'translate.whodunnit_challenging.guest.hint.hint1': "There are two main things that changed after the blackout. One is clearly visible, the other has to do with one of the suspects.",
            'translate.whodunnit_challenging.guest.hint.hint2': "There is a trail that goes from the front door to the bar. Watch the first 8-12 seconds of the video closely and compare this with the video of 1:33 to 1:38. What difference do you see? And what is the reason for this?",
            'translate.whodunnit_challenging.guest.hint.solution': "(1) The Caretaker and (2) the Lady planned to steal the totem.  But the real thief knew of their plans. The thief planned to use (3) chloroform to take out (4) the Lady, but when the blackout started the Boy (5) was in his way. When the thief approached the totem room, the Lady (6) got scared and hid in a closet. The thief pushed over a bowl, the Lady (6) screamed, alerting the Owner (7). The thief had no choice but to escape by (8) the window. When the thief re-entered, they left a trail which led to the (9 bar. There, the thief hid his (10) shoes because on the video the Baron shows is white socks while he had shoes on before the blackout. So we know the thief is the baron (11).",
    }
);

export default enPuzzleParadise;
