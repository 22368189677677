const enGeneral = ({
    tagOptionsDialog: {
        availableOptions: "Available options ({{count}})",
        submitButton: "Submit",
        clearBtn: "Clear",
        selected: "Selected",
        inOrder: "in order",
    },
    dragOptionsDialog: {
        availableOptions: "Available options ({{count}})",
        textChallengeTitle: "Fill in the gaps",
        textChallengeDescription: "Drag'n'drop options to fill in the missing parts",
        submitButton: "Submit",
    },
    taggerCarousel: {
        altText: "tag image",
        confirmButton: "Confirm",
        submitButtonText: "Submit",
        tagAllImages: "Tag all the images"
    },
    resultsDialog: {
        resultsTitle: "Results",
        resultsScoreDifficultyCap: "You are two smart detectives! We believe you deserve a higher difficulty level. You will get more points and different information. And also no more help from Steve... The main player can change the difficulty level in the menu, so you can always go back to standard difficulty.",
        hintsUsed: "Total Hints used",
        incorrectScore: "Total Incorrect score",
        solutionsTried: "Total Solutions tried",
        timeSpent: "Time Spent",
        totalPlayerHintsUsed: "Total Player Hints Used",
        totalGuestHintsUsed: "Total Guest Hints Used",
        totalPlayerSolutionsTried: "Total Incorrect Attempts",
        grandTotalResults: "Grand total results",
        totalScore: "Total score",
        congratulationsText: "Congratulations! You've successfully cracked the case together!",
        waitMessage: "Wait for the other player to press results and try again!",
        goToMenu: "Go To Menu",
        continueButton: "Continue",
        curiousFriends: "Do you want to know your friends score?",
        shareWithFriends: "Invite them and see how they did!",
        shareMessage: "{{playerName}} and {{partnerName}} challenge you for a tv escape room! They scored {{score}} for {{puzzleName}} on {{gameName}}. Do you accept this challenge? Go to {{link}}",
        shareGrandTotalMessage: "{{playerName}} and {{partnerName}} challenge you for a tv escape room! They scored {{score}} for on {{gameName}}. Do you accept this challenge? Go to {{link}}"
    },
    continuePuzzleVideo: {
        'Intro 1': 'Is everybody ready? Then start the next video - Intro 2 - and press Continue!',
        'Intro 2': 'Is everybody ready to start the game? Then start the next video - Open the door - and press Continue!',

        // the lab
        'Deceased persons': 'Is everybody ready for the next challenge? Then start the next video  - Password - and press Continue!',
        'Differences': 'Watch the next video and press \'xxxxxxx  Differences\' if you are both ready for the next challenge!',
        'Drone': 'Is everybody ready for the next challenge? Then start the next video  - Poison - and press Continue!',
        'Enclose': 'Well done, you solved it together! Press Continue to watch your total score and challenge your friends!',
        'Escape': 'Watch the next video and press \'xxxxxxx   Escape\' if you are both ready for the next challenge!',
        'Open the door': 'Is everybody ready for the next challenge? Then start the next video  - Deceased Persons - and press Continue. ',
        'Password': 'Is everybody ready for the next challenge? Then start the next video  - Drone - and press Continue!',
        'Poison': 'Is everybody ready for the next challenge? Then start the next video  - Who did it - and press Continue!',
        'Tools and wires': 'Watch the next video and press \'xxxxxxx  Tools and wires\' if you are both ready for the next challenge!',
        'Who did it': 'Is everybody ready for the next challenge? Then start the next video  - Enclose - and press Continue!',

        // paradise
        'The Blackout': 'Are you ready for the next challenge? Then start the next video  - 3. The Witnesses - and press Continue!',
        'The Traces': 'Are you ready for the next challenge? Then start the next video - 9. The Security Tapes - and press Continue!',
        'The Key': 'Are you ready for the next challenge? Then start the next video -  5. The Interrogation - and press Continue!',
        'Whodunnit': 'Well done, you solved it together! Press Continue to watch your total score and challenge your friends!',
        'The Missing Items': 'Are you ready for the next challenge? Then start the next video -  8. The Traces - and press Continue!',
        'The Knight': 'Are you ready for the next challenge? Then start the next video  - 2. The Blackout - and press Continue. ',
        'The Witnesses': 'Are you ready for the next challenge? Then start the next video  - 4. The Key - and press Continue!',
        'The Interrogation': 'Are you both ready for the next challenge? Then start the next video  - 6. The Card Game - and press Continue!',
        'The Security Tapes': 'Are you ready for the next challenge? Then start the next video - 10. Whodunnit - and press Continue!',
        'The Card Game': 'Are you ready for the next challenge? Then start the next video  - 7. The Missing Items - and press Continue!',
    },
    puzzleCard: {
        difficultyPlayed: "Difficulty played:",
        hintsUsed: "Number of hints used:",
        solutionsTried: "Number of solutions tried:",
        expires: "Expires",
        seeStats: "See stats"
    },
    problemModal: {
        sorry: "We're really sorry about the hiccup! But don't worry, we're here to guide it back on track.",
        clickContinue: "By clicking continue, you will reset this challenge and you should be able to continue.",
        areYouSure: "Are you sure?",
        reset: "Reset",
        guestNeedRejoin: "The guest needs to re-join the game again. Please share the link with the guest player and ask them to join.",
        inviteContinue: "Invited, continue!",
        doneOrSupport: "That's it! Now let's reload the page and the game will go on. In case this issue persist, contact the support.",
        reloadPage: "Reload page",
        problem: "Problem?",
    },
    errorModal: {
        difficultyChanged: {
            title: "Difficulty changed",
            description: "The host has changed the difficulty of the game. You will need to rejoin this puzzle in order to continue.",
            button: "Rejoin"
        },
        unknown: {
            title: "Error",
        }
    },
    instructionsModal: {
        // TODO: translations will depend by puzzle?
        instruction1: "How to play<br>" +
            "<br>A Toxic Case is a two player game about a missing person case. To play the game, both players need their own smartphone. Each player will receive different information about the case and a question they will need to answer." +
            "<br>Together you will watch a video about the case. The goal is to answer the question by discussing the information you received with the other player." +
            "<br>The videos are shown on our CouchClues Youtube Channel. We suggest using a television, but you can watch them on any screen you like." +
            "<br>Each video has an introduction, which is part of the story and then a case you will need to solve. Each case is repeated multiple times, so you won't have to restart the video.",
        instruction2: "Rules and options<br>" +
            "<br>When you start a puzzle, a timer will start. You can try to solve the puzzles as fast as possible in order to receive the most points. Or you can ignore the score and take as long as you like." +
            "<br>If you don't understand a puzzle, you can get a hint. Each puzzle has an explanation hint, which tells you more about what you need to do, and 2 hints that help you with the actual puzzle." +
            "<br>Both players can use the same hint or you can read the hint again, without using additional points." +
            "<br>If the hints are not enough to solve the puzzle, you can click the Solution option to read the answer.",
        instruction3: "Starting the game<br>" +
            "<br>The player that has purchased the game will receive a game link, which will give them access to the game. This will be the main player." +
            "<br>When the main player starts the game, they will be asked to share a second link with the other player. This second link allows the other player to join as guest and enter their own name." +
            "<br>The game will start with 2 intro challenges to get you acquainted with the game" +
            "<br>If you experience bugs or other problems, the main player can use the 'Problem' button in this menu to get you back on track.",
        btnText: 'Continue'
    },
    navbar: {
        inviteFriend: "Invite a friend",
        instructions: "Instructions",
        instructionsUrl: "https://couchclues.com/instructions/",
        problem: "Problem?",
        quit: "Quit",
    },
    difficultyModal: {
        areYouSure: "Are you sure you want to change difficulty to",
        dialogContent: "By changing the difficulty you will reset your current challenge. \n Your guest player will need to reload the page to continue. Are you sure you want to proceed?",
        cancel: "Cancel",
        confirm: "Confirm",
    },
    gameSeat: {
        hostSubtitle: "Main Player",
        guestSubtitle: "Guest Player",
        youTag: "YOU",
        emptyTitle: "Seat is empty",
        emptySubtitle: "Guest"
    },
    puzzleWaitingStartModal: {
        titleHostPov: "Waiting for players to join",
        titleGuestPov: "Waiting for puzzle to start",
        titleError: "Join error",
        puzzleIsFull: "Puzzle is full",
        startGame: "Start game",
        waitForStartAlert: "Wait for the main player to start the game",
        startingIn: "Starting in {{ secondsRemaining }}"
    },
    puzzleWaitingAnswersModal: {
        title: "Waiting for other players"
    },
    areYouStillThereModal: {
        title: "Are you still playing?",
        confirm: "Yes"
    },
    joinNewMemberModal: {
        title: "{{ name }} has invited you to play",
        input: {
            name: {
                label: "Enter your name"
            },
            email: {
                label: "Enter your email",
                error: "Please enter a valid email"
            }
        },
        submitButtonText: "Join"
    }
})

export default enGeneral;
