import gameRoomApi from "../api/gameRoomApi";
import {useTranslation} from "react-i18next";

const useSaveLanguageChange = ({ roomId, callback }) => {
    const {i18n} = useTranslation();
    return () => {
        gameRoomApi.setLang(roomId, i18n.language).then(() => {
            if (callback) {
                callback();
            }
        }).catch(console.error);
    };
}

export default useSaveLanguageChange;