import {createBrowserRouter} from "react-router-dom";
import ErrorPage from "./pages/error-page";
import PuzzlePage from "./pages/PuzzlePage";
import PuzzleListPage from "./pages/PuzzleListPage";
import PaymentPage from "./pages/PaymentPage";
import RoomContextProvider from "./context/RoomContextProvider";
import PuzzleContextProvider from "./context/PuzzleContextProvider";
import PuzzleWaitingAnswersContextProvider from "./context/PuzzleWaitingAnswersContextProvider";
import GlobalErrorModalProvider from "./components/GlobalErrorModalProvider";

const router = createBrowserRouter([
    // { path: "/sandbox", element: <Sandbox />, errorElement: <ErrorPage /> },
    {path: "/payment", element: <PaymentPage/>, errorElement: <ErrorPage/>},
    {
        path: "/:roomId",
        element: (
            <GlobalErrorModalProvider>
                <RoomContextProvider>
                    <PuzzleListPage/>
                </RoomContextProvider>
            </GlobalErrorModalProvider>
        ),
        errorElement: <ErrorPage/>,
    },
    {
        path: "/:gameId/:groupId",
        element: (
            <GlobalErrorModalProvider>
                <RoomContextProvider>
                    <PuzzleContextProvider>
                        <PuzzleWaitingAnswersContextProvider>
                            <PuzzlePage/>
                        </PuzzleWaitingAnswersContextProvider>
                    </PuzzleContextProvider>
                </RoomContextProvider>
            </GlobalErrorModalProvider>
        ),
        errorElement: <ErrorPage/>,
    },
    {
        path: "/:gameId/:groupId/guest",
        element: (
            <GlobalErrorModalProvider>
                <RoomContextProvider>
                    <PuzzleContextProvider>
                        <PuzzleWaitingAnswersContextProvider>
                            <PuzzlePage/>
                        </PuzzleWaitingAnswersContextProvider>
                    </PuzzleContextProvider>
                </RoomContextProvider>
            </GlobalErrorModalProvider>
        ),
        errorElement: <ErrorPage/>,
    },
    {
        path: "/:gameId/:groupId/guest/:memberId",
        element: (
            <GlobalErrorModalProvider>
                <RoomContextProvider>
                    <PuzzleContextProvider>
                        <PuzzleWaitingAnswersContextProvider>
                            <PuzzlePage/>
                        </PuzzleWaitingAnswersContextProvider>
                    </PuzzleContextProvider>
                </RoomContextProvider>
            </GlobalErrorModalProvider>
        ),
        errorElement: <ErrorPage/>,
    },
    {path: "/error/*", element: <ErrorPage/>, errorElement: <ErrorPage/>},
    {path: "/*", element: <ErrorPage/>, errorElement: <ErrorPage/>},
]);

export default router;
