import * as React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {modalStyle} from "../../style/modalStyle";
import {Alert, Button, Stack} from "@mui/material";
import PuzzleSeatList from "../game-seats/PuzzleSeatList";
import Countdown from "react-countdown";
import {usePuzzleContext} from "../../context/PuzzleContextProvider";
import {useRoomContext} from "../../context/RoomContextProvider";
import InviteLinkBox from "../InviteLinkBox";
import {useTranslation} from "react-i18next";

export default function PuzzleWaitingStartModal() {
    const { t } = useTranslation('puzzleWaitingStartModal')
    const {
        roomId,
        myMemberId,
        isHost,
    } = useRoomContext();

    const {
        puzzleId,
        startPuzzleEnabled,
        puzzleStartsAt,
        handleCountdown,
        secondsRemaining,
        isPuzzleStartInProgress,
        isPuzzleStarted,
        requestPuzzleStart,
        puzzleJoinResult,
    } = usePuzzleContext();

    const onStartPuzzleClick = isHost
        ? () => requestPuzzleStart({roomId, puzzleId})
        : undefined;

    return (
        <>
            {!!puzzleStartsAt && <Countdown date={new Date(puzzleStartsAt)} renderer={handleCountdown}/>}
            <Modal open={!!myMemberId && !isPuzzleStarted}>
                <Stack sx={modalStyle} gap={2}>
                    {puzzleJoinResult?.isSuccess && (
                        <Typography variant="h6" component="h2">
                            {isHost
                                ? t('titleHostPov')
                                : t('titleGuestPov')}
                        </Typography>
                    )}

                    {puzzleJoinResult?.isError && (
                        <Stack gap={2}>
                            <Typography variant="h6" component="h2">
                                {t('titleError')}
                            </Typography>
                            <Alert severity="error">
                                {puzzleJoinResult?.error?.response?.status === 429
                                    ? t('puzzleIsFull')
                                    : puzzleJoinResult?.error?.response?.data?.message}
                            </Alert>
                        </Stack>

                    )}


                    <PuzzleSeatList/>

                    {isHost && !isPuzzleStartInProgress && (
                        <Stack
                            mb={1}
                            sx={{ borderTop: '1px solid', borderColor: '#313b50' }}
                            pt={2}
                        >
                            <InviteLinkBox/>
                        </Stack>
                    )}
                    {isHost && !isPuzzleStartInProgress && (
                        <Button
                            variant="contained"
                            disabled={!startPuzzleEnabled}
                            sx={{opacity: startPuzzleEnabled ? 1 : 0.4 }}
                            onClick={onStartPuzzleClick}
                        >
                            {t('startGame')}
                        </Button>
                    )}

                    {!isHost && !isPuzzleStartInProgress && (
                        <Alert severity="warning">
                            {t('waitForStartAlert')}
                        </Alert>
                    )}

                    {isPuzzleStartInProgress && !puzzleJoinResult.isError && (
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {t('startingIn', { secondsRemaining })}
                        </Typography>
                    )}
                </Stack>
            </Modal>
        </>
    );
}
