import {useDraggable, useDroppable} from "@dnd-kit/core";
import {Box, Button} from "@mui/material";

export default function Draggable({children, id = 'draggable'}){
    const {
        attributes,
        listeners,
        setNodeRef,
        transform
    } = useDraggable({
        id,
    });
    const style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    } : {};


    return (
        <Button
            ref={setNodeRef}
            sx={{
                ...style,
                touchAction: 'none',
                color: 'black',
                borderRadius: '6px',
                fontSize: '17px',
                height: '42px',
                backgroundColor: '#DCF8C7',
                textTransform: 'none',
        }}
            {...listeners}
            {...attributes}
        >
            {children}
        </Button>
    );
}