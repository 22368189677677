import {coreClient} from './config';

const gameApi = {
    getGame: (roomId, puzzleId, isGuest) => coreClient
            .get(`/game/${roomId}/${puzzleId}?isGuest=${isGuest}`)
            .then(({data}) => data),
    didAnyPlayerOpenedHint: (gameId, uuid, isGuest, hintType) => coreClient
            .get(`/game/${gameId}/${uuid}/hint/usage?isGuest=${isGuest}&type=${hintType}`)
            .then(({data}) => data),
    setPlayerReady: (gameId, uuid, isGuest, difficulty, guestName) => coreClient
        .post(`/game/${gameId}/${uuid}/player-ready?isGuest=${isGuest}&difficulty=${difficulty}`, {guestName})
        .then(({data}) => data),
    resetPuzzle: (gameId, uuid) => coreClient
        .post(`/game/${gameId}/${uuid}/reset-puzzle`),
};
export default gameApi;
